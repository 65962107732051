export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;
export const LINKS = {
    REQUIRED_DOCS: 'https://thereliefconsultants.com/erc-required-documents.pdf',
};

export const ERC_STATUS = {
    LEAD_SCHEDULED: 'Lead - Scheduled',
    LEAD_NO_SHOW: 'Lead - No Show',
    LEAD_UNRESPONSE: 'Lead - Unresponsive',
    LEAD_APP_IN_PROGRESS: 'Lead - App in Progress',
    LEAD_CONTRACT_SENT: 'Lead - Contract Sent',
    LEAD_NOT_QUALIFIED: 'Lead - Not Qualified',
    LEAD_SELF_EMPLOYED: 'Lead - Self Employed',
    LEAD_UNRESPONSIVE: 'Closed - Unresponsive',
    CLOSED_AWAITING_DOCS: 'Closed - Awaiting Documents',
    CLOSED_PROCESSING_DOCS: 'Closed - Processing Documents',
    CLOSED_SIGNATURES: 'Closed - Signatures Required',
    CLOSED_READY_MAIL: 'Closed - Ready to Mail',
    CLOSED_PAYMENTS: 'Closed - Awaiting Payments',
    PAID_UNPAID: 'Paid - Unpaid',
    PAID_UNRESPONSIVE_UNPAID: 'Paid - Unresponsive Unpaid',
    PAID_PAID_PARTIAL: 'Paid - Partial Payment',
    PAID_PAID_IN_FULL: 'Paid - In Full',
};

let index = 0;
export const ERC_STATUS_ORDER = {
    LEAD_SCHEDULED: index++,
    LEAD_NO_SHOW: index++,
    LEAD_UNRESPONSE: index++,
    LEAD_APP_IN_PROGRESS: index++,
    LEAD_CONTRACT_SENT: index++,
    LEAD_NOT_QUALIFIED: index++,
    LEAD_SELF_EMPLOYED: index++,
    LEAD_UNRESPONSIVE: index++,
    CLOSED_AWAITING_DOCS: index++,
    CLOSED_PROCESSING_DOCS: index++,
    CLOSED_SIGNATURES: index++,
    CLOSED_READY_MAIL: index++,
    CLOSED_PAYMENTS: index++,
    PAID_UNPAID: index++,
    PAID_UNRESPONSIVE_UNPAID: index++,
    PAID_PAID_PARTIAL: index++,
    PAID_PAID_IN_FULL: index++,
};

export const APP_STATUS = [
    54, // Lead - No Appointment
    56, // Lead - Scheduled
    58, // Lead - No Show
    74, // Lead - Unresponsive
    64, // Lead - App in Progress
    62, // Lead - Contract Sent
    60, // Lead - Not Qualified
    92, // Lead - Self Employed
    76, // Closed - Unresponse
    66, // Closed - Awaiting Documents
    68, // Closed - Processing Documents
    70, // Closed - Signatures Required
    86, // Closed - Ready to Mail
    72, // Closed - Awaiting Payments
    78, // Paid - Unpaid
    84, // Paid - Unresponsive Unpaid
    80, // Paid - Partial Payment
    82, // Paid - In Full
];

export const SE_REASONS = {
    selfPeriod1: [
        'I was subject to a federal, state, or local quarantine or isolation order related to COVID-19.',
        'I was advised by a health care provider to self-quarantine due to concerns related to COVID-19.',
        'I was experiencing symptoms of COVID-19 and seeking a medical diagnosis.',
    ],
    familyPeriod1: [
        'I was caring for an individual who was subject to a federal, state, or local quarantine or isolation order related to COVID-19.',
        'I was caring for an individual who was advised by a health care provider to self-quarantine due to concerns related to COVID-19.',
        'I was caring for a son or daughter because the school or place of care for that child was closed and/or the childcare provider for that child was unavailable due to COVID-19 precautions.',
    ],
    childPeriod1: [
        'I was unable to perform services as an eligible self-employed individual because of certain coronavirus-related care I provided to a son or daughter whose school or place of care was closed or whose childcare provider was unavailable for reasons related to COVID-19.',
    ],
    selfPeriod2: [
        'I was subject to a federal, state, or local quarantine or isolation order related to COVID-19.',
        'I was advised by a health care provider to self-quarantine due to concerns related to COVID-19.',
        'I was experiencing symptoms of COVID-19 and seeking a medical diagnosis.',
        'I was seeking or awaiting the results of a diagnostic test for, or a medical diagnosis of COVID-19.',
        'I was exposed to COVID-19 or were unable to work pending the results of a test or diagnosis.',
        'I was obtaining immunization related to COVID-19.',
        'I was recovering from any injury, disability, illness, or condition related to such immunization.',
    ],
    familyPeriod2: [
        'I was caring for an individual who was subject to a federal, state, or local quarantine or isolation order related to COVID-19.',
        'I was caring for an individual who was advised by a health care provider to self-quarantine due to concerns related to COVID-19.',
        'I was caring for a son or daughter because the school or place of care for that child was closed and/or the childcare provider for that child was unavailable due to COVID-19 precautions.',
        'I was accompanying an individual to obtain immunization.',
        'I was caring for an individual who is recovering from any injury, disability, illness, or condition related to immunization.',
    ],
    childPeriod2: [
        'I was unable to perform services as an eligible self-employed individual because of certain coronavirus-related care I provided to a son or daughter whose school or place of care is closed or whose childcare provider was unavailable for reasons related to COVID-19 or for any reason I could claim sick leave equivalent credits.',
    ],
};
