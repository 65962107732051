import React, { useEffect, useRef, useState } from 'react';
// MUI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
// Components
// Styles
import useStyles from './styles';
import { PayrollInstructions } from 'utils/data/payroll-instructions';
import { ExpandMore } from '@mui/icons-material';

export default function PayrollInstructionsBox() {
    const classes = useStyles();
    const [payrollHelper, setPayrollHelper] = useState(0);
    const [open, setOpen] = useState(false);
    const currentInstructions = PayrollInstructions[payrollHelper];
    const initialLoad = useRef();

    useEffect(() => {
        if (initialLoad.current) {
            setOpen(true);
        } else initialLoad.current = true;
    }, [payrollHelper]);

    return (
        <Accordion expanded={open} onChange={(e, v) => setOpen(v)}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Box sx={{ width: '70%' }}>
                    <Typography
                        variant="font1"
                        sx={{ fontWeight: '500' }}
                        mb={1}
                    >
                        Choose your payroll provider to get started
                    </Typography>

                    <Select
                        value={payrollHelper}
                        onChange={(e) => setPayrollHelper(e.target.value)}
                        fullWidth
                    >
                        {PayrollInstructions.map((instruction, i) => {
                            return (
                                <MenuItem value={i} key={`instruct-${i}`}>
                                    {instruction.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    className={classes.payrollInstructions}
                    sx={{ marginTop: 3 }}
                >
                    <Typography variant="h5" fontWeight="600">
                        {currentInstructions.title}
                    </Typography>
                    <Typography variant="font1" fontWeight="500">
                        {currentInstructions.subtitle}
                    </Typography>
                    <ul>
                        {currentInstructions.lines.map((line, idx) => {
                            return (
                                <li key={`line-${idx}`}>
                                    <Typography variant="font2">
                                        {line}
                                    </Typography>
                                </li>
                            );
                        })}
                    </ul>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
