import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: '100vh',
        display: 'flex',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
        },
    },
    imageContainer: {
        overflow: 'hidden',
        width: '50%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        '& > img': {
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
            objectPosition: 'right',
        },

        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },

    actions: {
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        boxShadow: theme.olShadow[0],
        marginLeft: -20,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: 'calc(50% + 20px)',

        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginLeft: 0,

            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            padding: '8% 24px 20% 24px',
        },
    },
}));

export default useStyles;
