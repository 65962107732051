import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import cloneDeep from 'lodash/cloneDeep';
import { SET_ACCOUNT, GET_ACCOUNT } from 'redux/reducers/account';

export default class AccountRedux {
    static current = () => async (dispatch, getState) => {
        const { account, user } = getState();
        if (!account) return;

        const { model } = account;
        const response = await axios.get(`${ApiResource.endpoint}/portal/accounts/${model.AccountableId}`, {
            headers: { 'x-auth': user.authToken },
        });

        dispatch({
            type: GET_ACCOUNT,
            payload: { model, ...response.data },
        });
    };

    static get = (account) => async (dispatch, getState) => {
        const user = getState().user;
        const response = await axios.get(`${ApiResource.endpoint}/portal/accounts/${account.AccountableId}`, {
            headers: { 'x-auth': user.authToken },
        });

        dispatch({
            type: GET_ACCOUNT,
            payload: { model: account, ...response.data },
        });
    };

    static update = (body) => async (dispatch, getState) => {
        const { user, account } = getState();

        const response = await axios.patch(`${ApiResource.endpoint}/portal/applications/${account.model.AccountableId}`, body, {
            headers: { 'x-auth': user.authToken },
        });

        dispatch({
            payload: { ...cloneDeep(account), ...response.data },
            type: SET_ACCOUNT,
        });
    };

    static set = (payload) => (dispatch, getState) => {
        const account = cloneDeep(getState().account);
        dispatch({
            type: SET_ACCOUNT,
            payload: { ...account, ...payload },
        });
    };

    static reset = () => (dispatch, getState) => {
        dispatch({
            type: SET_ACCOUNT,
            payload: null,
        });
    };
}
