export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const ACCOUNTS_ERROR = 'ACCOUNTS_ERROR';
export const INIT = [];

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_ACCOUNTS:
            return payload;
        case ACCOUNTS_ERROR:
        default:
            return state;
    }
}

export default reducer;
