import React from 'react';
// Modules
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { store, persistor } from './redux/config';

// Material UI
import { ThemeProvider } from '@mui/material/styles';

// CSS Imports
import './utils/string';
import './globals.css';

import theme from './theme';
import { ConfirmProvider } from 'material-ui-confirm';
import { CssBaseline } from '@mui/material';

const App = (props) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ConfirmProvider>
                    <Router />
                </ConfirmProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
