import React from 'react';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';

export default function Information() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { account } = useSelector(({ account }) => ({ account }));

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.title}>
                    <Typography variant="h3">Company Information</Typography>
                </Box>
                <Paper className={classes.paper}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Company Name
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.fields.companyName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                EIN
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.fields.ein}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Contact
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.firstName} {account?.lastName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Phone Number
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.phone}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Email
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Address
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.fields.address} {account?.fields.state} {account?.fields.city} {account?.fields.zip}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper} sx={{ marginTop: 3 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Consultant name
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.User?.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Consultant contact phone
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.User?.phone}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" fontWeight="600" mb={1}>
                                Consultant Email
                            </Typography>
                            <Typography variant="h6" fontWeight="300">
                                {account?.User?.email}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
}
