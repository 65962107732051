import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    paper: {
        backgroundImage: 'none',
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 24,
        paddingRight: 24,
    },
    dialogTitle: {
        borderBottom: '1px solid white',
        marginBottom: 16,
    },
}));

export default useStyles;
