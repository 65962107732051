import * as React from 'react';
import { useSelector } from 'react-redux';
// MUI
import { IconButton, Box, Drawer, List, Typography } from '@mui/material';
// images
import Logo from 'assets/images/logos/logo-long-dark.png';
import { Close } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
// styles
import useStyles from './styles';
import { SEMenuItems, ERCMenuItems } from '../data';

export default function MobileDrawer({ open, onClose, onLogout }) {
    const { user, account } = useSelector(({ user, account }) => ({ user, account }));
    const classes = useStyles();
    const location = useLocation();

    React.useEffect(() => {
        onClose();
    }, [location.pathname]);

    function handleAction(action) {
        if (action === 'logout') {
            onLogout();
        }
    }

    const MenuItems = account.type === 'ERC' ? ERCMenuItems : account.type === 'self-employment' ? SEMenuItems : [];

    return (
        <Drawer anchor={'right'} open={open} classes={{ paper: classes.drawer }}>
            <Box role="presentation" className={classes.drawerBox}>
                <Box className={classes.drawerHeader}>
                    <img src={Logo} className={classes.logo} />
                    <IconButton onClick={onClose}>
                        <Close size={16} />
                    </IconButton>
                </Box>
                <List>
                    <Box className={classes.navItems}>
                        {MenuItems.map((item, i) => {
                            if (item.require === 'metadata.showQualMatrix') {
                                if (!account.metadata.showQualMatrix) return;
                            } else if (item.require && !user?.[item.require]) return;

                            if (item.type === 'text') {
                                return (
                                    <Box key={i} in className={classes.navTitle}>
                                        <Typography variant="font3">{item.title}</Typography>
                                    </Box>
                                );
                            }

                            if (item.type === 'link') {
                                let path = item.to;
                                if (item.require) {
                                    path = path.replace(':id', user[item.require]);
                                }

                                return (
                                    <Box key={i} component={Link} to={item.to} className={classes.navItem}>
                                        <Box className={classes.navItemIcon}>
                                            <item.Icon />
                                        </Box>
                                        <Typography>{item.title}</Typography>
                                    </Box>
                                );
                            }

                            if (item.type === 'action') {
                                return (
                                    <Box key={i} onClick={() => handleAction(item.action)} className={classes.navItem}>
                                        <Box className={classes.navItemIcon}>
                                            <item.Icon />
                                        </Box>
                                        <Typography>{item.title}</Typography>
                                    </Box>
                                );
                            }

                            return null;
                        })}
                    </Box>
                </List>
            </Box>
        </Drawer>
    );
}
