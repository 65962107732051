import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    animationContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    animation: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        flexDirection: 'column',
    },
}));

export default useStyles;
