import React, { useEffect, useState } from 'react';
// Modules
import AccountRedux from 'redux/actions/account';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
// MUI
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@mui/material';
// Components
import Card from 'components/Card/Card';
import FullscreenLoader from 'components/Widgets/FullscreenLoader';
import DocumentAccordion from 'components/Document/DocumentAccordion/DocumentAccordion';
// Styles
import useStyles from './styles';
import { ExpandMore, Security } from '@mui/icons-material';

const FAQS_LEAD_SETC = [
    {
        title: 'What tax years do I need to upload?',
        body: <p>You need to upload your full Federal tax returns for the years 2019, 2020, and 2021.</p>,
    },
    {
        title: 'Can I upload a portion of my Tax Return?',
        body: <p>No, the full return is required for each of the specified tax years.</p>,
    },
    {
        title: 'Can I take a photo of my tax returns?',
        body: <p>No, returns must be sent via PDF. If you only have paper copies you will need to scan and upload in one file.</p>,
    },
    {
        title: 'Why do you prefer IRS Copies, rather than Client Copies?',
        body: <p>IRS Copies are preferred because Client Copies can delay the processing of the Credit.</p>,
    },
    {
        title: 'Why do you need my full tax return?',
        body: (
            <p>
                In order to claim the Self-Employed Tax Credit (SETC), a taxpayer must have certain items in their Federal tax returns. There are also a few
                things you must not have. By uploading your returns, your Consultant will be able to identify if your status meets the criteria for being
                eligible for the SETC.
            </p>
        ),
    },
    {
        title: 'Is your Upload Portal Secure?',
        body: (
            <p>
                Yes, your returns are only available to those reviewing your SETC eligibility on a need-to-know basis. Since 2020, The Relief Consultants have
                practiced the highest security standards, ensuring their clients' confidentiality and have kept client data safe and secure since day 1.
            </p>
        ),
    },
    {
        title: `What if I'm not able to provide my returns until the time of my appointment?`,
        body: (
            <p>
                No problem. If you cannot provide your tax returns at the time of your appointment, your Consultant will still be able to offer an on-the-spot
                review of your Returns so to provide context about your potential eligibility for the SETC.
            </p>
        ),
    },
    {
        title: `What if I don't have my returns in time for my appointment?`,
        body: (
            <p>
                If you don't have your Federal Tax Returns ready for your appointment, don't worry. During your consultation, your Consultant will send an
                upload link to your email. Once you have your returns, you can upload them using this link. Your Consultant will then contact you at their
                earliest convenience to discuss your eligibility for the SETC.
            </p>
        ),
    },
];

export default function SEUpload() {
    const classes = useStyles();
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));

    const [application, setApplication] = useState(null);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const [accordions, setAccordions] = useState([]);

    useEffect(() => {
        setApplication(cloneDeep(account));
        setFiles(cloneDeep(account.documents.uploads));
    }, [account]);

    const handleAccordion = (value) => {
        const _accordions = cloneDeep(accordions);
        const index = _accordions.indexOf(value);
        if (index === -1) _accordions.push(value);
        else _accordions.splice(index, 1);

        setAccordions(_accordions);
    };

    function handleNewFile(fArr, newFiles) {
        if (!fArr) return;
        // name, key, type
        const _files = cloneDeep(files ?? []);
        _files.push(...newFiles);
        setFiles(_files);

        handleSubmit(newFiles);
    }

    async function getPresignedUrl(filename, filetype) {
        const {
            data: { url },
        } = await axios.post(
            `${ApiResource.endpoint}/portal/applications/presigned/${application.model.AccountableId}`,
            {
                filename,
                filetype,
            },
            {
                headers: { 'x-auth': user.authToken },
            },
        );

        return url;
    }

    async function handleSubmit(_files) {
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/portal/applications/files/${application.model.AccountableId}`,
                {
                    uploads: _files,
                },
                {
                    headers: { 'x-auth': user.authToken },
                },
            );

            await dispatch(AccountRedux.set(response.data));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    if (!application) return <FullscreenLoader />;

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Typography variant="font1" mb={1}>
                        In order to verify your eligibility for the SETC, we need copies of your 2019, 2020, and 2021 Federal tax returns. Upload them securely
                        to your client portal below.
                    </Typography>
                    <Typography variant="h4" fontWeight={'600'} mb={1}>
                        What's needed?
                    </Typography>
                    <ul style={{ paddingTop: 0, marginTop: 0 }}>
                        <li>
                            <Typography variant="font3" fontWeight={'bold'}>
                                2019 - 2021 Federal Tax Returns (3 Years)
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="font3" fontWeight={'bold'}>
                                IRS Copies if possible
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="font3" fontWeight={'bold'}>
                                PDF or scanned
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="font3" fontWeight={'bold'}>
                                Full tax file for each year
                            </Typography>
                        </li>
                    </ul>

                    <Typography variant="h4" fontWeight={'600'} mb={1}>
                        Why do you need my tax returns?
                    </Typography>
                    <ul style={{ paddingTop: 0, marginTop: 0 }}>
                        <li>
                            <Typography component={'a'} href="#faq" variant="font3" className="link" fontWeight={'bold'}>
                                See our FAQ below
                            </Typography>
                        </li>
                    </ul>
                </Box>
                <Card title="File Uploads">
                    <DocumentAccordion
                        immutable
                        application={application}
                        getPresignedUrl={getPresignedUrl}
                        expanded={accordions.includes(1)}
                        onOpen={() => handleAccordion(1)}
                        initialFiles={files || []}
                        onFilesChanged={(f, n) => handleNewFile(f, n, 'uploads')}
                    />
                </Card>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, mb: 2 }}>
                    <Security color="primary" />
                    <Typography variant="font3" fontWeight={'500'}>
                        Your information is being uploaded into a secure and encrypted server
                    </Typography>
                </Box>
                <Typography variant="h5" fontWeight={'600'} mt={2} mb={1}>
                    Uploading Tax Returns for SETC Claims with The Relief Consultants
                </Typography>
                {FAQS_LEAD_SETC.map((faq, i) => {
                    return (
                        <Accordion key={`faq-${i}`} className={classes.accordion} sx={{ marginBottom: 1, borderTop: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Box className={classes.accordionSummary}>
                                    {/* <FaEye className={classes.accordionIcon} /> */}
                                    <Box
                                        sx={{
                                            marginRight: 1.5,
                                            height: 10,
                                            width: 10,
                                            borderRadius: '50%',
                                            border: `1px solid primary`,
                                            backgroundColor: 'primary',
                                        }}
                                    />
                                    <Typography variant="font1" sx={{ fontWeight: '500' }}>
                                        {faq.title}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>{faq.body}</AccordionDetails>
                        </Accordion>
                    );
                })}
            </Container>
        </Box>
    );
}
