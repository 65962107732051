export const GET_ACCOUNT = 'GET_ACCOUNT';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR';
export const INIT = null;

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_ACCOUNT:
        case GET_ACCOUNT:
            return payload;
        case ACCOUNT_ERROR:
        default:
            return state;
    }
}

export default reducer;
