import dayjs from 'dayjs';

export function sortStringDates(dateStrings, format = 'MM/DD/YY') {
    // Convert string dates to Date objects
    const dateObjects = dateStrings.map((dateString) => new Date(dateString));

    // Sort Date objects
    dateObjects.sort((a, b) => a - b);

    // Convert sorted Date objects back to string format
    const sortedDateStrings = dateObjects.map((date) => dayjs(date).format(format));

    return sortedDateStrings;
}
