import React from 'react';
// UI
import { Box, Typography } from '@mui/material';
// images
import Sample941 from 'assets/images/sample-941.png';
import useStyles from './styles';

export default function TaxDetails() {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography
                variant="font2"
                sx={{ fontWeight: '400', marginTop: 1, marginBottom: 3 }}
            >
                To assist in calculating your credit we need the Federal forms
                filled for your payroll. Please upload the applicable Federal
                forms for{' '}
                <span className="bold underline">2020 (Q2, Q3, Q4)</span> and{' '}
                <span className="bold underline">2021 (Q1, Q2, Q3)</span>.
                Please also include any 941-x forms filed during this period. If
                you took the WOTC (Work Opportunity Tax Credit) or the R&D
                (Research & Development) credit, please also upload this
                information.
            </Typography>

            <Typography
                variant="font3"
                sx={{ fontWeight: '400', marginBottom: 0.5 }}
            >
                Sample 941
            </Typography>
            <Box className={classes.sample}>
                <img src={Sample941} className={classes.image} />
            </Box>
        </Box>
    );
}
