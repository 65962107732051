import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useStyles from './styles';
import { EXTRA_STEPS, STEPS } from './data';
import { useSelector } from 'react-redux';
import DisclaimerDialog from 'components/Dialogs/DisclaimerDialog/DisclaimerDialog';
import CalendarSelection from './CalendarSelection/CalendarSelection';
import Confirmation from './Confirmation/Confirmation';
import AwaitingConsultant from './AwaitingConsultant/AwaitingConsultant';
import FullscreenLoader from 'components/Widgets/FullscreenLoader';
import { cloneDeep } from 'lodash';

/**
How I understand you want it to work:
    Period 1: Select Sick Leave, up to 10 days
    Period 1: Select Family leave, up to X days, x = 10 - sick leave period 1 days selected
    Period 2: Select Sick Leave, up to 10 days
    Period 2: Select Family leave, up to X days, x = 10 - sick leave period 2 days selected
    Period 1: Select child leave, up to 10 days
    Period 2: Select child leave up to 50 days
Blanket Rules:
    Can not double dip on days, any days previously selected can not be selected again.
    If days are selected, atleast one reason must be selected as well
    If user selected 10 days in Sick Leave section, bypass family leave section
 */

export default function Affidavit() {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));

    const [steps, setSteps] = useState([]);
    const [step, setStep] = useState(account.metadata.portalStep || 0);
    const currentStep = step === 'signature' ? { key: 'signature' } : steps[step];

    useEffect(() => {
        if (account) {
            const _steps = cloneDeep(STEPS);
            // if (account.metadata.unemploymentClaim) {
            //     _steps.unshift(EXTRA_STEPS[1]);
            // }
            if (account.metadata.w2SickLeave) {
                _steps.unshift(EXTRA_STEPS[0]);
            }

            setSteps(_steps);
            setStep(account.metadata.portalStep || 0);
        }
    }, [account]);

    function handleBack({ dates }) {
        window.scrollTo(0, 0);
        let portalStep;
        if (step === 'signature') {
            portalStep = 0;
        } else {
            portalStep = step - 1;
            let previousStep = steps[portalStep];
            if (previousStep.skip) {
                let previousPreviousStep = steps[step - 2];
                if (dates[previousPreviousStep.key].length === previousPreviousStep.maxDays) {
                    portalStep--;
                    previousStep = steps[portalStep];
                }
            }
        }

        setStep(portalStep);
    }

    if (!currentStep) return <FullscreenLoader />;

    return (
        <Box className={classes.root}>
            <DisclaimerDialog />
            {/* {account?.metadata?.readyToSignAffidavit ? <Confirmation onBack={handleBack} /> : <AwaitingConsultant onBack={handleBack} />} */}
            {currentStep.key === 'signature' && account?.metadata?.readyToSignAffidavit ? (
                <Confirmation onBack={handleBack} />
            ) : currentStep.key === 'signature' && !account?.metadata?.readyToSignAffidavit ? (
                <AwaitingConsultant onBack={handleBack} />
            ) : (
                <CalendarSelection onBack={handleBack} steps={steps} step={step} />
            )}
        </Box>
    );
}

/**
 * {currentStep.key === 'signature' && account?.metadata?.readyToSignAffidavit ? (
        <Confirmation onBack={handleBack} />
    ) : currentStep.key === 'signature' && !account?.metadata?.readyToSignAffidavit ? (
        <AwaitingConsultant onBack={handleBack} />
    ) : (
        <CalendarSelection onBack={handleBack} steps={steps} step={step} />
    )}
 */
