import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    content: {},

    paper: {
        padding: theme.spacing(2),
        borderRadius: 4,
        height: '100%',
    },

    breakdownList: {
        '& > div:not(:last-child)': {
            borderBottom: '1px solid rgba(0,0,0,0.1)',
        },
    },
    breakdown: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 0px',
    },
    breakdownAction: {
        textAlign: 'right',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
}));

export default useStyles;
