import { makeStyles } from '@mui/styles';

import Background from 'assets/images/backgrounds/white-abstract.jpg';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },

    animation: {
        width: '20%',
    },

    controls: {
        paddingTop: theme.spacing(3),
        borderTop: `1px solid rgba(0,0,0,0.2)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    button: {
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 28,
        paddingRight: 28,
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

export default useStyles;
