import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        padding: theme.spacing(2),
        borderRadius: 4,
    },

    title: {
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid #E4E4E4`,
    },
}));

export default useStyles;
