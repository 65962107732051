import * as React from 'react';
// components
import { AppBar, Box, Divider, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { AccountCircle, ExitToApp, ExpandMore, Menu } from '@mui/icons-material';
import MobileDrawer from '../MobileDrawer/MobileDrawer';
// Styles
import Logo from 'assets/images/logos/logo-long-dark.png';
import DefaultUser from 'assets/images/icons/default-user.png';
import useStyles from './styles';
import NotificationsList from '../NotificationsList/NotificationsList';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HoverDropdown from 'components/HoverDropdown/HoverDropdown';
import ChangeAccountButton from 'components/Buttons/ChangeAccountButton/ChangeAccountButton';

export default function TopNav({ handleLogout }) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    return (
        <AppBar color="white" sx={{ borderRadius: 0 }}>
            <Toolbar className={classes.toolbar}>
                <Hidden mdDown>
                    <Box className={classes.leftContent}>
                        <img src={Logo} className={classes.logo} />
                        {/* <Dashboard />
                        <Typography variant="font2">Relief Consultants Client Dashboard</Typography> */}
                    </Box>
                </Hidden>
                <Box className={classes.rightContent}>
                    <Hidden mdDown>
                        <ChangeAccountButton />
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton onClick={() => setDrawerOpen(true)}>
                            <Menu />
                        </IconButton>
                    </Hidden>
                    <Box className={classes.row}>
                        <NotificationsList />
                        <HoverDropdown
                            typeProps={{
                                variant: 'font1',
                                sx: { fontWeight: '500' },
                            }}
                            title={
                                <Box className={classes.profileDropdown}>
                                    <img src={DefaultUser} className={classes.profilePicture} />
                                    <Typography variant="font2" fontWeight={'500'}>
                                        Hi, {user.name}
                                    </Typography>
                                    <ExpandMore color="lightText" />
                                </Box>
                            }
                        >
                            <Box className={classes.dropdownContent}>
                                <Box className={classes.menuItem} component={Link} to={`/`}>
                                    <AccountCircle color="lightText" />
                                    <Typography variant="font3" fontWeight={'500'}>
                                        My Account
                                    </Typography>
                                </Box>
                                <Divider sx={{ marginY: 1, borderColor: 'rgba(0,0,0,0.06)' }} />
                                <Box className={classes.menuItem} onClick={handleLogout}>
                                    <ExitToApp color="lightText" />
                                    <Typography variant="font3" fontWeight={'500'}>
                                        Logout
                                    </Typography>
                                </Box>
                            </Box>
                        </HoverDropdown>
                    </Box>
                </Box>
            </Toolbar>

            <MobileDrawer onLogout={handleLogout} open={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </AppBar>
    );
}
