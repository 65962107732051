import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    reasons: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    reason: {
        padding: theme.spacing(1.5),
        border: '1px solid #a4a4a4',
        borderRadius: 12,
        cursor: 'pointer',
        transition: '0.25s all ease-in',
    },
    selectedReason: {
        border: 'none',
        border: '1px solid white',
        color: 'white',
    },
    divider: {
        borderBottom: '1px solid #E4E4E4',
        width: '100%',
        height: 1,
    },

    dates: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: 24,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    dateCalendar: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderRadius: 4,
            border: '1px solid #d1d1d1',
        },
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 12,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 999,
        [theme.breakpoints.down('sm')]: {
            bottom: 50,
        },
    },

    blue: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
    },
    red: {
        backgroundColor: `${colors.red[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.red[600]} !important`,
        },
    },
    orange: {
        backgroundColor: `${colors.orange[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.orange[600]} !important`,
        },
    },
    yellow: {
        backgroundColor: `${colors.yellow[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.yellow[600]} !important`,
        },
    },
    green: {
        backgroundColor: `${colors.green[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.green[600]} !important`,
        },
    },
    purple: {
        backgroundColor: `${colors.purple[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.purple[600]} !important`,
        },
    },

    grey: {
        backgroundColor: `${colors.grey[500]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.grey[400]} !important`,
        },
    },

    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 12,
    },
    sectionColor: {
        height: 60,
        width: 60,
    },
    logo: {
        height: 60,
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    hidden: {
        display: 'none',
    },

    calendarImage: {
        maxWidth: '100%',
    },
}));

export default useStyles;
