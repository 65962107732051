import React, { useState } from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Login } from '@mui/icons-material';
import clsx from 'clsx';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

export default function Referral() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));

    async function handleLogin() {
        setLoading(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/v2/authentication?to=referral-partner&template=Referral`, {
                headers: { 'x-auth': user.authToken },
            });
            window.open(response.data.link, '_blank');
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setLoading(false);
    }

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.title}>
                    <Typography variant="h3">Referral Network</Typography>
                </Box>
                <Paper className={classes.paper}>
                    <Typography variant="font1" fontWeight="300" mb={3}>
                        Simply put, the referral partner program by The Relief Consultants is a way for you to earn money while encouraging businesses to take
                        advantage of grants, credits, and tax relief that they're eligible for. We believe the best way to connect with businesses both large
                        and small is through individuals like yourselves -- and the existing networks you have.Simply put, the referral partner program by The
                        Relief Consultants is a way for you to earn money while encouraging businesses to take advantage of grants, credits, and tax relief that
                        they're eligible for.
                    </Typography>
                    <Typography variant="font1" fontWeight="300">
                        We believe the best way to connect with businesses both large and small is through individuals like yourselves -- and the existing
                        networks you have.
                    </Typography>
                </Paper>

                <Box
                    onClick={handleLogin}
                    className={clsx(classes.login, {
                        [classes.disabledLogin]: loading,
                    })}
                    sx={{ boxShadow: 2 }}
                    mt={2}
                >
                    <Typography variant="h5">Login to my Referral Dashboard</Typography>
                    <Box className={classes.loginIcon}>
                        <Login />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
