const samplePayroll = [
    {
        name: 'Harry Potter',
        date: '04/13/2021',
        healthcare: '$100',
        wage: '$3,200',
    },
    {
        name: 'Neo Anderson',
        date: '04/13/2021',
        healthcare: '$100',
        wage: '$2,223',
    },
    {
        name: 'Jack Sparrow',
        date: '04/13/2021',
        healthcare: '$100',
        wage: '$2,200',
    },
    {
        name: 'Davey Jones',
        date: '04/13/2021',
        healthcare: '$100',
        wage: '$3,550',
    },
    {
        name: 'Harry Potter',
        date: '05/13/2021',
        healthcare: '$100',
        wage: '$3,200',
    },
    {
        name: 'Neo Anderson',
        date: '05/13/2021',
        healthcare: '$100',
        wage: '$2,223',
    },
    {
        name: 'Jack Sparrow',
        date: '05/13/2021',
        healthcare: '$100',
        wage: '$2,200',
    },
    {
        name: 'Davey Jones',
        date: '05/13/2021',
        healthcare: '$100',
        wage: '$3,550',
    },
];

export default samplePayroll;
