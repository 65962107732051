import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { APP_STATUS } from 'utils/constants';
import SignedImg from 'assets/images/stock/signed.png';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

export default function Signature941() {
    const classes = useStyles();
    const [notifying, setNotifying] = useState();
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));
    const networking = useRef(false);
    const dispatch = useDispatch();

    async function handleNotify() {
        if (networking.current) return;
        networking.current = true;
        setNotifying(true);
        try {
            await axios.post(
                `${ApiResource.endpoint}/portal/applications/notify/${account?.model?.AccountableId}`,
                {
                    document: '941x Documents',
                },
                {
                    headers: {
                        'x-auth': user.authToken,
                    },
                },
            );

            dispatch(setSnackbar({ message: 'Your consultant has been notified' }));
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setNotifying(false);
        networking.current = false;
    }

    const statusIndex = APP_STATUS.indexOf(account?.status);
    const indexGreater = APP_STATUS.indexOf(86);

    return (
        <Box className={clsx(classes.root, classes.center)} sx={{ maxWidth: 500 }}>
            {statusIndex < indexGreater && (
                <>
                    <Typography variant="h6" fontWeight={'600'} mb={2}>
                        Sign your 941x documents to complete the credit documentation
                    </Typography>
                    <Typography variant="font2" fontWeight={'400'} mb={2}>
                        Don’t see your 941x documents in your email? Click{' '}
                        <Typography
                            fontWeight={'700'}
                            component="span"
                            color={notifying ? 'disabled.main' : 'primary'}
                            style={{ cursor: 'pointer' }}
                            onClick={handleNotify}
                        >
                            here
                        </Typography>{' '}
                        to notify your consultant.
                    </Typography>
                    <Typography variant="font2" fontWeight={'400'}>
                        This is the final step! Once signed, we’ll mail these to the IRS on your behalf.
                    </Typography>
                </>
            )}
            {statusIndex >= indexGreater && (
                <>
                    <Typography variant="h6" fontWeight={'600'} mb={2}>
                        Thank you for signing your 941x documents
                    </Typography>
                    <Typography variant="font1" fontWeight={'400'}>
                        These will be mailed to the IRS for processing your employee retention credit.
                    </Typography>
                </>
            )}

            <Box className={classes.imgContainer} mt={3}>
                <img src={SignedImg} className={classes.img} />
            </Box>
        </Box>
    );
}
