import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';

export default function Agreements() {
    const classes = useStyles();
    const [notifying, setNotifying] = useState();
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));
    const networking = useRef(false);
    const dispatch = useDispatch();

    async function handleNotify() {
        if (networking.current) return;
        networking.current = true;
        setNotifying(true);
        try {
            await axios.post(
                `${ApiResource.endpoint}/portal/applications/notify/${account?.model?.AccountableId}`,
                {
                    document: 'Work Order Agreement',
                },
                {
                    headers: {
                        'x-auth': user.authToken,
                    },
                },
            );

            dispatch(setSnackbar({ message: 'Your consultant has been notified' }));
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setNotifying(false);
        networking.current = false;
    }

    return (
        <Box className={clsx(classes.root)}>
            {(!account?.docusign?.clientContract || account?.docusign?.clientContract?.status !== 'completed') && (
                <Box mb={2} maxWidth={400}>
                    <Typography variant="font2" fontWeight={'600'} mb={2}>
                        Check your email for a Docusign from support@thereliefconsultants.com
                    </Typography>
                    <Typography variant="font3" fontWeight={'400'}>
                        Didn't receive your document? Click{' '}
                        <Typography
                            fontWeight={'700'}
                            component="span"
                            color={notifying ? 'disabled.main' : 'primary'}
                            style={{ cursor: 'pointer' }}
                            onClick={handleNotify}
                        >
                            here
                        </Typography>{' '}
                        to notify your consultant
                    </Typography>
                </Box>
            )}
            {account?.docusign?.clientContract?.status === 'completed' && (
                <Box mb={2} maxWidth={400}>
                    <Typography variant="font2" fontWeight={'600'} mb={2}>
                        Client Contract Completed
                    </Typography>
                    <Typography variant="font3" fontWeight={'400'}>
                        It looks like you have finished signing the client contract. Next steps are you upload important documents to help us calculate your
                        credit!
                    </Typography>
                </Box>
            )}
            <Box mb={2} maxWidth={400}>
                <Typography variant="font2" fontWeight={'600'}>
                    Work Agreement
                </Typography>
                <Typography variant="font3" fontWeight={'400'}>
                    This allows us to work together in calculating and claiming your employee retention credit
                </Typography>
            </Box>
            <Box mb={2} maxWidth={400}>
                <Typography variant="font2" fontWeight={'600'}>
                    Form 8821
                </Typography>
                <Typography variant="font3" fontWeight={'400'}>
                    We'll use this form to send to the IRS so we can check on your refund until it arrives
                </Typography>
            </Box>
            {/* <Box mb={2} maxWidth={400}>
                <Typography variant="font2" fontWeight={'600'}>
                    Form 8822-B
                </Typography>
                <Typography variant="font3" fontWeight={'400'}>
                    This enables us to disperse your employee retention credit
                    funds when they're received
                </Typography>
            </Box> */}
        </Box>
    );
}
