import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    /** Drawer */
    logo: {
        maxWidth: '70%',
    },

    drawer: {
        width: '100%',
        backgroundImage: 'none',
        borderRadius: '0px !important',
    },
    drawerBox: {
        padding: 20,
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: theme.spacing(4),
    },

    navTitle: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textTransform: 'uppercase',
    },
    navDot: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        '&:before': {
            display: 'block',
            content: '""',
            height: 8,
            width: 8,
            borderRadius: '50%',
            backgroundColor: 'rgba(255,255,255,0.7)',
            marginLeft: 18,
        },
    },
    navItems: {
        '& > *:not(last-child)': {
            marginTop: theme.spacing(2),
        },
    },
    navItem: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
        cursor: 'pointer',

        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    navItemIcon: {
        boxShadow: '0 3px 12px #0000000a',
        backgroundColor: 'white',
        borderRadius: 6,
        padding: '12px 16px',
        color: '#2e2828',
    },
}));

export default useStyles;
