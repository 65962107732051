import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, InputAdornment, Typography } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Card/Card';

import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { LoadingButton } from '@mui/lab';
import Logo from 'assets/images/logos/logo-long-dark.png';
import Calendar1Image from 'assets/images/backgrounds/calendar1.png';
import { useDispatch, useSelector } from 'react-redux';
import { transformData, transformDates, transformReasons } from '../data';
import AccountRedux from 'redux/actions/account';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import NumericInput from 'components/inputs/NumericInput';
import dayjs from 'dayjs';

function Day(props) {
    const { day, selectedDays, classes, disabledDays, color, ...other } = props;

    const selected = selectedDays.findIndex((item) => new Date(item).getTime() === new Date(day).getTime()) !== -1;
    const disabled = disabledDays.findIndex((item) => new Date(item).getTime() === new Date(day).getTime()) !== -1;

    return <PickersDay day={day} {...other} selected={selected} disabled={disabled} classes={{ selected: classes[color] }} />;
}

export default function CalendarSelection({ onBack, step, steps }) {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));
    const [saving, setSaving] = useState(false);
    const [dates, setDates] = useState(transformDates(account));
    const [reasons, setReasons] = useState(transformReasons(account));
    const [paidPerDay, setPaidPerDay] = useState('');
    const dispatch = useDispatch();
    const currentStep = steps[step];
    const [error, setError] = useState({});
    const paidRef = useRef();

    useEffect(() => {
        if (account) {
            setDates(transformDates(account));
            setReasons(transformReasons(account));

            if (currentStep.key === 'w2leave') {
                setPaidPerDay(account?.forms?.affidavit?.w2SickPaidPerDay || '');
            }
        }
    }, [account.forms?.affidavit]);

    function movePageDown() {
        // window.scrollTo({ top: window.document.body.scrollHeight, behavior: 'smooth' });
        dispatch(setSnackbar({ message: 'Max days selected', severity: 'info' }));
    }

    async function handleNext({ skip = false }) {
        const key = currentStep.key;
        if (currentStep.reasons) {
            if (dates?.[key]?.length > 0 && reasons?.[key]?.length === 0) {
                return dispatch(setSnackbar({ severity: 'error', message: 'If you select a date, a reason must be selected' }));
            }
            if (dates?.[key]?.length === 0 && reasons?.[key]?.length > 0) {
                return dispatch(setSnackbar({ severity: 'error', message: 'If you select a reason, dates must be selected' }));
            }
        }

        if (currentStep.type === 'w2') {
            if (!Boolean(paidPerDay)) {
                dispatch(setSnackbar({ severity: 'error', message: 'Please enter the amount you were paid per day for sick leave' }));
                setError({ paid: true });
                return paidRef.current.focus();
            }
        }

        setError({});

        // Skip if nothing selected
        if (dates?.[key]?.length === 0 && reasons?.[key]?.length === 0) skip = true;

        const portalStep = getNextStep();

        setSaving(true);
        try {
            const transformed = transformData(dates[currentStep.key], reasons[currentStep.key], currentStep.type, currentStep.part, skip, paidPerDay);
            await dispatch(
                AccountRedux.update({
                    [`forms.affidavit`]: transformed,
                    [`metadata.portalStep`]: portalStep,
                }),
            );
            window.scrollTo(0, 0);
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setSaving(false);
    }

    function getNextStep() {
        let portalStep = step + 1;
        let nextStep = steps[portalStep];
        if (nextStep.skip) {
            if (dates?.[currentStep.key].length === currentStep.maxDays) {
                portalStep++;
            }
        }

        return portalStep;
    }

    function handleSelectReason(reason) {
        const key = currentStep.key;

        const _reasons = cloneDeep(reasons);
        if (!_reasons[key]) _reasons[key] = [];

        const index = _reasons[key].indexOf(reason);
        if (index === -1) _reasons[key].push(reason);
        else _reasons[key].splice(index, 1);
        setReasons(_reasons);
    }

    function handleSelectDate(date, item) {
        const key = currentStep.key;

        const _dates = cloneDeep(dates);
        if (!_dates[key]) _dates[key] = [];

        let additionalMaxDays = 0;
        if (currentStep.addDaysFrom) {
            additionalMaxDays = dates?.[currentStep.addDaysFrom]?.length || 0;
        }

        const index = _dates[key].findIndex((item) => new Date(item).getTime() === new Date(date).getTime());
        if (index === -1) {
            if (_dates[key].length + additionalMaxDays === currentStep.maxDays) return movePageDown();
            if (['unemployment'].includes(currentStep.type)) addWeek(_dates, date, item);
            else _dates[key].push(date);
        } else {
            if (['unemployment'].includes(currentStep.type)) removeWeek(_dates, date, item);
            else _dates[key].splice(index, 1);
        }
        setDates(_dates);
    }

    function addWeek(dates, date, item) {
        const key = currentStep.key;
        const startOfWeek = dayjs(date).startOf('week');
        for (let i = 0; i < 7; i++) {
            const currentDate = startOfWeek.add(i, 'day');
            if (currentDate.isBefore(item.minDate) || currentDate.isAfter(item.maxDate)) continue;
            dates[key].push(currentDate);
        }
    }

    function removeWeek(dates, date) {
        const key = currentStep.key;
        const startOfWeek = dayjs(date).startOf('week');
        dates[key] = dates[key].filter((date) => {
            // Check if the date is not in the same week as the given date
            return !dayjs(date).isSame(startOfWeek, 'week');
        });
    }

    const disabledDays = currentStep?.disabledDates?.map?.((key) => dates?.[key] ?? [])?.flat?.() || [];
    const selectedDays = dates?.[currentStep.key] || [];

    let additionalMaxDays = 0;
    if (currentStep.addDaysFrom) {
        additionalMaxDays = dates?.[currentStep.addDaysFrom]?.length || 0;
    }

    return (
        <Container maxWidth="xs">
            <Box
                className={clsx(classes.center, {
                    [classes.hidden]: step !== 0,
                })}
                sx={{ mb: 2 }}
            >
                <img src={Logo} className={classes.logo} />
            </Box>
            <Card
                titleChildren={
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography mb={0.5} variant="h5" fontWeight={'600'}>
                            Self-Employment
                        </Typography>
                        <Typography mb={1} variant="h5" fontWeight={'600'}>
                            Sick & Family Leave Credit
                        </Typography>
                        <Typography mb={1} variant="font1">
                            Eligibility Questionnaire
                        </Typography>
                        <img src={Calendar1Image} className={classes.calendarImage} />
                    </Box>
                }
            >
                <Box className={classes.title} sx={{ mb: 2 }}>
                    <Box className={clsx(classes.sectionColor, classes[currentStep.periodColor])} />
                    <Box>
                        <Typography variant="h6">{currentStep.title}</Typography>
                        <Typography variant="font3" fontWeight={'400'}>
                            {currentStep.dates}
                        </Typography>
                        {currentStep.reasons && (
                            <Typography variant="font2" fontWeight={'500'} fontStyle={'italic'}>
                                Select ALL that apply:
                            </Typography>
                        )}
                    </Box>
                </Box>
                {currentStep.key === 'w2leave' && (
                    <NumericInput
                        inputProps={{ ref: paidRef }}
                        label="Sick leave paid per day"
                        error={error?.paid}
                        helperText={error?.paid && 'Input required'}
                        fullWidth
                        value={paidPerDay}
                        onChange={(e) => {
                            setPaidPerDay(e.target.value);
                            if (error?.paid) setError({});
                        }}
                        size="small"
                        precision={2}
                        decimalChar="."
                        thousandChar=","
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                )}
                {currentStep.reasons && (
                    <Box className={classes.reasons}>
                        {currentStep.reasons.map((reason) => {
                            return (
                                <Box
                                    className={clsx(classes.reason, {
                                        [classes.selectedReason]: reasons?.[currentStep.key]?.includes?.(reason),
                                        [classes[currentStep.color]]: reasons?.[currentStep.key]?.includes?.(reason),
                                    })}
                                    key={reason}
                                    onClick={() => handleSelectReason(reason)}
                                >
                                    <Typography variant="font1">{reason}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {currentStep.reasons && (
                    <Box className={classes.center} sx={{ mt: 4 }}>
                        <Button disabled={saving} onClick={() => handleNext({ skip: true })}>
                            None of the above
                        </Button>
                    </Box>
                )}
                {/* {!currentStep.reasons && (
                    <Box className={classes.center} sx={{ mt: 2 }}>
                        <Button disabled={saving} onClick={() => handleNext({ skip: true })}>
                            This does not apply to me
                        </Button>
                    </Box>
                )} */}
                <Box className={classes.divider} sx={{ my: 3 }} />
                {currentStep.maxDays && (
                    <Typography fontStyle={'italic'} mb={3}>
                        Select up to {currentStep.maxDays - additionalMaxDays} days below in which any of the above reasons resulted in you being unable to
                        work:
                    </Typography>
                )}
                {!currentStep.maxDays && (
                    <Typography fontStyle={'italic'} mb={3}>
                        Select days below in which this applied:
                    </Typography>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box className={classes.dates}>
                        {currentStep.calendar.map((item, i) => {
                            return (
                                <DateCalendar
                                    key={`${new Date(item.minDate)}-${i}`}
                                    view={'day'}
                                    className={classes.dateCalendar}
                                    sx={{ borderRadius: 4, border: '1px solid #d1d1d1' }}
                                    minDate={item.minDate}
                                    maxDate={item.maxDate}
                                    onChange={(date) => handleSelectDate(date, item)}
                                    slots={{ day: Day, leftArrowIcon: Box, rightArrowIcon: Box }}
                                    slotProps={{
                                        day: {
                                            selectedDays,
                                            disabledDays,
                                            classes: classes,
                                            color: currentStep.color,
                                        },
                                    }}
                                />
                            );
                        })}
                    </Box>
                </LocalizationProvider>
                <Box className={classes.actions} sx={{ py: 2 }}>
                    <LoadingButton disabled={step === 0} loading={saving} onClick={() => onBack({ dates })} variant="contained" size="large" fullWidth>
                        <Typography variant="h6">BACK</Typography>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={() => handleNext({})} variant="contained" size="large" fullWidth>
                        <Typography variant="h6">NEXT</Typography>
                    </LoadingButton>
                </Box>
            </Card>
        </Container>
    );
}
