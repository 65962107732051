import React, { useRef, useState } from 'react';
import { Box, Button, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Download } from '@mui/icons-material';
import dayjs from 'dayjs';
import ApiResource from 'integrations/api/api_resource';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReportDownloadButton from 'components/Buttons/ReportDownloadButton/ReportDownloadButton';

export default function Documents() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [downloading, setDownloading] = useState(false);
    const networking = useRef();
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));

    async function getDocument(filename, path, endPath) {
        if (networking.current) return;
        networking.current = true;
        setDownloading(filename);
        try {
            let url = `${ApiResource.endpoint}/portal/accounts/documents/${account.model.AccountableId}`;

            const {
                data: { link },
            } = await axios.post(
                url,
                {
                    path,
                    endPath,
                },
                {
                    headers: { 'x-auth': user.authToken },
                },
            );

            window.open(link, '_blank');
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setDownloading(false);
        networking.current = false;
    }

    function renderRows() {
        return Object.keys(account?.documents.uploads ?? {}).map((type) => {
            return account?.documents.uploads?.[type]?.map?.((doc, i) => {
                return (
                    <TableRow
                        key={`row-${i}`}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                        }}
                    >
                        <TableCell component="th" scope="row">
                            {type.toUpperCase()}
                        </TableCell>
                        <TableCell align="right">{dayjs(account.updatedAt).format('MMM DD YYYY')}</TableCell>
                        <TableCell align="right">
                            {doc.filename.slice(0, 20)}
                            {doc.filename.length > 20 && '...'}
                        </TableCell>

                        <TableCell align="right">
                            <IconButton disabled={downloading === doc.filename} onClick={() => getDocument(doc.filename, `documents.uploads.${type}.${i}`)}>
                                <Download />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            });
        });
    }

    function renderWorksheets() {
        return Object.keys(account?.worksheets ?? {}).map((type, i) => {
            const doc = account.worksheets[type];
            return (
                <TableRow
                    key={`row-${i}`}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                    }}
                >
                    <TableCell component="th" scope="row">
                        {type.split('-')[0].toUpperCase()}
                    </TableCell>
                    <TableCell align="right">{dayjs(account.updatedAt).format('MMM DD YYYY')}</TableCell>
                    <TableCell align="right">
                        {doc.filename.slice(0, 20)}
                        {doc.filename.length > 20 && '...'}
                    </TableCell>

                    <TableCell align="right">
                        <IconButton disabled={downloading === doc.filename} onClick={() => getDocument(doc.filename, `worksheets`, type)}>
                            <Download />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        });
    }

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.header}>
                    <Typography variant="h3">Documents</Typography>
                    <Button to="/upload" component={Link} variant="contained">
                        Upload more documents
                    </Button>
                </Box>
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>TYPE</TableCell>
                                    <TableCell align="right">DATE</TableCell>
                                    <TableCell align="right">FILE NAME</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderRows()}
                                {renderWorksheets()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <ReportDownloadButton sx={{ marginTop: 2 }} />
            </Container>
        </Box>
    );
}
