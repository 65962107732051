import React, { useState, useRef } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import useStyles from './styles';

export default function HoverDropdown({ children, open, title = '', typeProps = {}, ...props }) {
    const classes = useStyles(props);

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const popoverEnter = ({ currentTarget }) => {
        setOpenedPopover(true);
    };

    const popoverLeave = ({ currentTarget }) => {
        setOpenedPopover(false);
    };

    return (
        <Box className={classes.root}>
            <Box ref={popoverAnchor} aria-owns="mouse-over-popover" aria-haspopup="true" onMouseEnter={popoverEnter} onMouseLeave={popoverLeave} {...typeProps}>
                {title}
            </Box>
            <Popover
                id="mouse-over-popover"
                sx={{ marginTop: 1 }}
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent,
                }}
                open={Boolean(openedPopover || open)}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
            >
                {children}
            </Popover>
        </Box>
    );
}
