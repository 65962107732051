import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import Card from 'components/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useEffect, useRef, useState } from 'react';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { LoadingButton } from '@mui/lab';
import AccountRedux from 'redux/actions/account';
import dayjs from 'dayjs';

export default function Expedite() {
    const { account, user } = useSelector(({ account, user }) => ({ account, user }));
    const [downloading, setDownloading] = useState(false);
    const sigCanvas = useRef();
    const sigCanvas2 = useRef();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);
    const [errs, setErrors] = useState({});
    const signed = Boolean(account?.forms?.form911?.signature);
    const [checkboxes, setCheckboxes] = useState({
        confirmed: signed ? true : '',
        confirmed8821: signed ? true : '',
        confirmed8821_2: signed ? true : '',
    });

    const { register, setValue, handleSubmit, control, getValues, watch } = useForm({
        defaultValues: {
            title: account?.forms?.form911?.title,
            line1a: account?.forms?.form911?.line1a || account?.fields?.companyName,
            line1b: account?.forms?.form911?.line1b || account?.fields?.ein,
            line2a: account?.forms?.form911?.line2a || '',
            line2b: account?.forms?.form911?.line2b || '',
            line3a: account?.forms?.form911?.line3a || account?.fields?.address,
            line3b: account?.forms?.form911?.line3b || account?.fields?.city,
            line3c: account?.forms?.form911?.line3c || account?.fields?.state,
            line3d: account?.forms?.form911?.line3d || account?.fields?.zip,
            line4: account?.forms?.form911?.line4 || '',
            line5: account?.forms?.form911?.line5 || account?.email,
            line6: account?.forms?.form911?.line6 || '',
            line7a: account?.forms?.form911?.line7a || account?.phone,
            confirm7a: account?.forms?.form911?.confirm7a || true,
            line7b: account?.forms?.form911?.line7b || true,
            taxForms: '941, 941x',
            taxPeriods: 'Q2, Q3, Q4 of 2020 & Q1, Q2, Q3, Q4 of 2021',
        },
    });

    useEffect(() => {
        if (account?.forms?.form911?.signature) {
            sigCanvas?.current?.clear?.();
            sigCanvas?.current?.fromDataURL?.(account?.forms?.form911?.signature);
        }
        if (account?.forms?.form911?.signature8821) {
            sigCanvas?.current?.clear?.();
            sigCanvas?.current?.fromDataURL?.(account?.forms?.form911?.signature8821);
        }

        if (!account?.forms?.form911?.readyToSign) {
            sigCanvas?.current?.off?.();
            sigCanvas2?.current?.off?.();
        } else {
            sigCanvas?.current?.on?.();
            sigCanvas2?.current?.on?.();
        }
    }, [account]);

    async function handleSave(data) {
        setSaving(true);
        try {
            if (!checkboxes.confirmed || !checkboxes.confirmed8821 || !checkboxes.confirmed8821_2) {
                setErrors({ checkboxes: true });
                throw new Error('Please confirm the following information before continuing.');
            }

            if (sigCanvas.current.isEmpty()) {
                setErrors({ signature: true });
                throw new Error('Please sign in both spots before submitting');
            }
            if (sigCanvas2.current.isEmpty()) {
                setErrors({ signature: true });
                throw new Error('Please sign in both spots before submitting');
            }

            setErrors({});

            const signature = sigCanvas.current.toDataURL();
            const signature8821 = sigCanvas2.current.toDataURL();
            const response = await axios.patch(
                `${ApiResource.endpoint}/portal/applications/${account.model.AccountableId}`,
                {
                    ['forms.form911']: { ...data, dateSigned: dayjs().format('MM/DD/YYYY'), signature, signature8821 },
                },
                {
                    headers: {
                        'x-auth': user.authToken,
                    },
                },
            );
            dispatch(AccountRedux.set(response.data));
            dispatch(setSnackbar({ message: 'Information has been submitted' }));
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setSaving(false);
    }

    function handleCheckboxClicked(idx, value) {
        const _checkboxes = cloneDeep(checkboxes);
        _checkboxes[idx] = value;
        setCheckboxes(_checkboxes);
    }

    async function handleDownload8821() {
        setDownloading(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/portal/applications/${account.model.AccountableId}/8821`, {
                headers: { 'x-auth': user.authToken },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Form 8821.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setDownloading(false);
    }

    if (!account?.forms?.form911?.readyToSign) {
        return (
            <Card
                title="Form 911"
                subtitle={
                    <Typography variant="font3" fontWeight={'500'}>
                        This portal uses The Relief Consultants Client Application to fill necessary IRS forms to expedite your ERC claim processing. The
                        information below will be placed on relevant IRS forms exactly as it appears on this portal. By completing this form, you confirm that
                        the information entered below is true and correct and you authorize it to be placed on IRS forms on your behalf.{' '}
                    </Typography>
                }
            >
                <Typography>Contact your consultant to get started!</Typography>
                <Typography sx={{ mt: 2 }}>
                    Your consultant is {account?.User?.name} and can be contacted at {account?.User?.phone}
                </Typography>
            </Card>
        );
    }

    return (
        <Card
            title="Form 911"
            subtitle={
                <Typography variant="font3" fontWeight={'500'}>
                    This portal uses The Relief Consultants Client Application to fill necessary IRS forms to expedite your ERC claim processing. The
                    information below will be placed on relevant IRS forms exactly as it appears on this portal. By completing this form, you confirm that the
                    information entered below is true and correct and you authorize it to be placed on IRS forms on your behalf.{' '}
                </Typography>
            }
        >
            <Box className={classes.root} sx={{ overflowX: 'scroll' }}>
                <Grid container spacing={0} className={classes.gridContainer} sx={{ minWidth: '1141px' }}>
                    <Grid item xs={12}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>My Title</Typography>
                            <TextField {...register('title')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderLeft)}>
                            <Typography ml={2}>1a. Taxpayer name as shown on tax return (Business Name)</Typography>
                            <TextField {...register('line1a')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>1b. Taxpayer Identifying Number (SSN, TIN, EIN)</Typography>
                            <TextField {...register('line1b')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderLeft)}>
                            <Typography ml={2}>2a.</Typography>
                            <TextField
                                {...register('line2a')}
                                disabled={true}
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                                variant="filled"
                                size="small"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>2b.</Typography>
                            <TextField
                                {...register('line2b')}
                                disabled={true}
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                                variant="filled"
                                size="small"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>3a. Taxpayer current street address</Typography>
                            <TextField {...register('line3a')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>3b. City</Typography>
                            <TextField {...register('line3b')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box className={clsx(classes.formGrid, classes.borderTop, classes.borderRight)}>
                            <Typography ml={2}>3c. State </Typography>
                            <TextField {...register('line3c')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box className={clsx(classes.formGrid, classes.borderTop, classes.borderRight)}>
                            <Typography ml={2}>3d. ZIP code</Typography>
                            <TextField {...register('line3d')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>4.</Typography>
                            <TextField disabled={true} {...register('line4')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box className={clsx(classes.formGrid, classes.borderTop, classes.borderRight)}>
                            <Typography ml={2}>5. Email address </Typography>
                            <TextField {...register('line5')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>6.</Typography>
                            <TextField disabled={true} {...register('line6')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderTop, classes.borderLeft)}>
                            <Typography ml={2}>7a. Daytime phone number </Typography>
                            <TextField {...register('line7a')} fullWidth InputProps={{ disableUnderline: true }} variant="filled" size="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>7a. Is this a cell phone? </Typography>
                            <FormControlLabel
                                sx={{ ml: 2 }}
                                control={<Checkbox defaultChecked={account?.forms?.form911?.confirm7a || true} {...register(`confirm7a`)} />}
                                label="The phone number provided is a cell phone"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>
                                7b. Check here if you consent to have confidential information about your tax issue left on your answering machine or voice
                                message at this number{' '}
                            </Typography>
                            <FormControlLabel
                                sx={{ ml: 2 }}
                                control={<Checkbox defaultChecked={account?.forms?.form911?.line7b || true} {...register(`line7b`)} />}
                                label="I Consent"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>
                                12a. Describe the tax issue you are experiencing and any difficulties it may be creating? (Filled by consultant)
                            </Typography>
                            <TextField fullWidth multiline rows={4} disabled={true} value={account?.forms?.form911?.line12a} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>12b. Describe the relief/assistance you are requesting (Filled by consultant) </Typography>
                            <TextField fullWidth multiline rows={4} disabled={true} value={account?.forms?.form911?.line12b} />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderTop, classes.borderLeft)}>
                            <Typography ml={2}>Tax Form</Typography>
                            <TextField
                                disabled={true}
                                {...register('taxForms')}
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                                variant="filled"
                                size="small"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <Typography ml={2}>Tax Periods</Typography>
                            <TextField
                                disabled={true}
                                {...register('taxPeriods')}
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                                variant="filled"
                                size="small"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box className={clsx(classes.formGrid, classes.borderTop)}>
                            <FormControlLabel
                                sx={{ mt: 2 }}
                                control={
                                    <Checkbox
                                        disabled={signed}
                                        defaultChecked={!!checkboxes.confirmed}
                                        value={checkboxes.confirmed}
                                        onChange={(_, checked) => handleCheckboxClicked('confirmed', checked)}
                                    />
                                }
                                label={
                                    <Typography variant="font2" fontWeight={'400'}>
                                        I understand that Taxpayer Advocate Service employees may contact third parties in order to respond to this request and
                                        I authorize such contacts to be made. Further, by authorizing the Taxpayer Advocate Service to contact third parties, I
                                        understand that I will not receive notice, pursuant to section 7602(c) of the Internal Revenue Code, of third parties
                                        contacted in connection with this request.
                                    </Typography>
                                }
                            />
                            {errs.checkboxes && (
                                <Typography color="error.main" variant="font2">
                                    Please confirm the following information before continuing.
                                </Typography>
                            )}
                            <Box sx={{ mt: 2 }} className={classes.sigContainer}>
                                <Typography color={errs?.signature ? 'error.main' : null} variant="font2">
                                    {account?.forms?.form911?.signature ? 'Your Signature Below:' : 'Sign Below:'}
                                </Typography>
                                <Typography variant="font3" fontWeight={'normal'}>
                                    By signing below I authorize my signature to be placed on a Form 911 Request for Taxpayer Advocate Service Assistance OMB
                                    1545-1504{' '}
                                    <a className="link" href="https://www.irs.gov/pub/irs-pdf/f911.pdf">
                                        (link to form)
                                    </a>{' '}
                                    and I authorize Relief Consultants, LLC and its representatives to submit this form on my behalf.
                                </Typography>
                                {account?.forms?.form911?.signature ? (
                                    <img src={account.forms.form911.signature} className={classes.signature} style={{ height: 100, width: 330 }} />
                                ) : (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                                        <ReactSignatureCanvas
                                            ref={sigCanvas}
                                            penColor="black"
                                            canvasProps={{ height: 100, width: 330, className: classes.signature }}
                                        />
                                        <Button onClick={() => sigCanvas?.current?.clear?.()} disabled={signed}>
                                            Clear Signature
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 4, pt: 4, borderTop: '1px solid rgba(0,0,0,0.2)' }}>
                            <FormControlLabel
                                sx={{ mt: 2 }}
                                control={
                                    <Checkbox
                                        disabled={signed}
                                        defaultChecked={!!checkboxes.confirmed8821}
                                        value={checkboxes.confirmed8821}
                                        onChange={(_, checked) => handleCheckboxClicked('confirmed8821', checked)}
                                    />
                                }
                                label={
                                    <Typography variant="font2" fontWeight={'400'}>
                                        I authorize Relief Consultants, LLC to work with the IRS regarding my Employee Retention Credit claim. I understand the
                                        8821 Form below confirms this authorization.{' '}
                                        <span onClick={handleDownload8821} className="link">
                                            View 8821 Authorization
                                        </span>
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                sx={{ mt: 2 }}
                                control={
                                    <Checkbox
                                        disabled={signed}
                                        defaultChecked={!!checkboxes.confirmed8821_2}
                                        value={checkboxes.confirmed8821_2}
                                        onChange={(_, checked) => handleCheckboxClicked('confirmed8821_2', checked)}
                                    />
                                }
                                label={
                                    <Typography variant="font2" fontWeight={'400'}>
                                        If signed by a corporate officer, partner, guardian, partnership representative (or designated individual, if
                                        applicable), executor, receiver, administrator, trustee, or individual other than the taxpayer, I certify that I have
                                        the legal authority to execute this form with respect to the tax matters and tax periods shown on line 3 of the 8821
                                        Form found above.
                                    </Typography>
                                }
                            />
                            {errs.checkboxes && (
                                <Typography color="error.main" variant="font2">
                                    Please confirm the following information before continuing.
                                </Typography>
                            )}
                            <Box sx={{ mt: 2 }} className={classes.sigContainer}>
                                <Typography color={errs?.signature ? 'error.main' : null} variant="font2">
                                    {account?.forms?.form911?.signature ? 'Your Signature Below:' : 'Sign Below:'}
                                </Typography>
                                <Typography variant="font3" fontWeight={'normal'}>
                                    By signing below I authorize my signature to be placed on a Form 8821 and I authorize Relief Consultants, LLC and its
                                    representatives to submit this form on my behalf.
                                </Typography>
                                {account?.forms?.form911?.signature8821 ? (
                                    <img src={account.forms.form911.signature8821} className={classes.signature} style={{ height: 100, width: 330 }} />
                                ) : (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                                        <ReactSignatureCanvas
                                            ref={sigCanvas2}
                                            penColor="black"
                                            canvasProps={{ height: 100, width: 330, className: classes.signature }}
                                        />
                                        <Button onClick={() => sigCanvas2?.current?.clear?.()} disabled={signed}>
                                            Clear Signature
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box className={classes.actions}>
                    <LoadingButton disabled={signed} loading={saving} variant="contained" onClick={handleSubmit(handleSave)}>
                        {signed ? 'Already submitted' : 'Submit'}
                    </LoadingButton>
                </Box>
            </Box>
        </Card>
    );
}
