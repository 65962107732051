export default function breakdown7202(forms) {
    const breakdown = {
        2020: { part1: 0, part2: 0 },
        2021: { part1: 0, part2: 0, part3: 0, part4: 0 },
        totals: { 2020: 0, 2021: 0, combined: 0 },
    };

    breakdown[2020].part1 = parseFloat(forms?.f7202_2020?.line24 ?? 0);
    breakdown[2020].part2 = parseFloat(forms?.f7202_2020?.line35 ?? 0);

    breakdown[2021].part1 = parseFloat(forms?.f7202_2021?.line24 ?? 0);
    breakdown[2021].part2 = parseFloat(forms?.f7202_2021?.line35 ?? 0);
    breakdown[2021].part3 = parseFloat(forms?.f7202_2021?.line58 ?? 0);
    breakdown[2021].part4 = parseFloat(forms?.f7202_2021?.line69 ?? 0);

    breakdown.totals[2020] = breakdown[2020].part1 + breakdown[2020].part2;
    breakdown.totals[2021] = breakdown[2021].part1 + breakdown[2021].part2 + breakdown[2021].part3 + breakdown[2021].part4;
    breakdown.totals.combined = breakdown.totals[2020] + breakdown.totals[2021];

    return breakdown;
}
