export const PayrollInstructions = [
    {
        name: 'QuickBooks Online',
        title: 'Instructions for Generating Payroll Report from QuickBooks Online:',
        subtitle: 'To download accurate payroll data from QuickBooks Online, follow the steps below:',
        lines: [
            `Log in to your QuickBooks Online account.`,
            `Click on the "Reports" tab and then select "Reports".`,
            `Scroll down to the "Payroll" section at the bottom of the page.`,
            `Choose "Payroll Details".`,
            `Under the "Date Range" section, select "Custom". a. Enter the date range as 3/13/2020 - 9/30/2021.`,
            `On the right-hand side of the screen, select "Customize".`,
            `Under the "Employee" section, select "All Employees".`,
            `Click on "Run Report".`,
            `At the top-right corner of the screen, click on "Share" and select "Export to Excel".`,
            `Save the downloaded file as an Excel Workbook named “Business Name_Date Range”.`,
        ],
    },
    {
        name: 'QuickBooks Desktop',
        title: 'Instructions for Generating Payroll Report from QuickBooks Desktop:',
        subtitle: 'To download accurate payroll data from QuickBooks Desktop, follow the steps below:',
        lines: [
            `Log in to your QuickBooks Desktop software.`,
            `Click on the "Reports" tab in the top menu bar.`,
            `Scroll down to "Employees & Payroll".`,
            `Select the “Payroll Transaction Detail" report.`,
            `Under the "Date Range" section, select "Custom".`,
            `a. Enter the date range as 3/13/2020 - 9/30/2021.`,
            `Choose "All Employees".`,
            `At the top of the report window, select the dropdown arrow next to "Excel" and select "Create New Worksheet".`,
            `Save the downloaded file as an Excel Workbook named “Business Name_Date Range”.`,
        ],
    },
    {
        name: 'Accounting CS',
        title: 'Instructions for Generating Payroll Report from Accounting CS:',
        subtitle: 'To download accurate payroll data from Accounting CS, follow the steps below:',
        lines: [
            `Go to the left panel and select "Print Reports".`,
            `Choose the "Payroll Journal - Detailed" report.`,
            `Enter the date range as 3/13/2020 - 9/30/2021 under the "Date" section.`,
            `Make sure to deselect "Direct Deposit Distributions" under "Sections to Include" to exclude employee direct deposit information from the report.`,
            `Click on "Print Selected" in the lower right corner of the screen.`,
            `In the "Print" window, unselect "Output to printer" and choose "Output to file" at the bottom of the window. Ensure the file type is set to "Microsoft Excel".`,
            `Check "Open file when complete" and select "OK".`,
            `Save the Excel file as an Excel Workbook and name it "Business Name_Date Range".`,
        ],
    },
    {
        name: 'Patriot',
        title: 'Instructions for Generating Payroll Report from Patriot:',
        subtitle: 'To download accurate payroll data from Patriot, follow the steps below:',
        lines: [
            `Log in to your Patriot payroll account and navigate to the "Reports" section on the left-hand panel.`,
            `From the "Payroll Reports" heading, select "Payroll Details".`,
            `Enter the date range as 3/13/2020 - 9/30/2021.`,
            `Under "Employees", select "Check all", and under "Source", select "Check all".`,
            `Under "Group By", choose "Check".`,
            `Run the report.`,
            `Click on "Download Spreadsheet".`,
            `Save the Excel file with the name "Business Name_Date Range".`,
        ],
    },
    {
        name: 'ADP Run',
        title: 'Instructions for Generating Payroll Report from ADP Run:',
        subtitle: 'To download accurate payroll data from ADP Run, follow the steps below:',
        lines: [
            `Log in to your ADP RUN payroll account.`,
            `Click on the "Reports" tab in the menu bar.`,
            `On the "Reports Home" page, select "Payroll Summary".`,
            `Note: Since ADP reports are organized by year, you will need to generate a report for both 2020 and 2021.`,
            `Under "From": choose a pay date on or before 3/13/2020 and under "To": select 12/31/2020.`,
            `Select "All" under the "Name" section.`,
            `Click on "Refresh" to update the report with the selected criteria.`,
            `Click on "Export to Excel" at the top of the report.`,
            `Save the downloaded file as an Excel Workbook named “Business Name_Date Range”. If possible, save all reports within the same Excel Workbook.`,
            `Repeat the process starting at step #3 for the date range: 1/1/2021 - 9/30/2021.`,
        ],
    },
    {
        name: 'Gusto',
        title: 'Instructions for Generating Payroll Report from Gusto:',
        subtitle: 'To download accurate payroll data from Gusto, follow the steps below:',
        lines: [
            `Log in to your Gusto payroll account.`,
            `Navigate to “Reports” on the left panel.`,
            `Select the report named “Payroll Journal Builder”.`,
            `Under "Step 1: Columns", select the following columns and deselect any not on this list:`,
            `a. Payroll type`,
            `b. Check date`,
            `c. Employee last name`,
            `d. Employee first name`,
            `e. Gross earnings`,
            `Scroll down to "Step 2: Filters" and select "Between" and enter the date range as 3/13/2020 - 9/30/2021.`,
            `Under "Step 3: Format", select "CSV".`,
            `Click on "Run Report".`,
            `Save the downloaded file as an Excel Workbook named “Business Name_Date Range”.`,
        ],
    },
    {
        name: 'Paychex',
        title: 'Instructions for Generating Payroll Report from Paychex:',
        subtitle: 'To download accurate payroll data from Paychex, follow the steps below:',
        lines: [
            `Log in to your Paychex payroll account.`,
            `Click on the "Reports" tab.`,
            `Select "Payroll Journal" from the available options.`,
            `Set the date range as 3/13/2020 - 9/30/2021.`,
            `Ensure that all employees are selected and that the report displays employee totals.`,
            `Download the spreadsheet.`,
            `Save the downloaded file as an Excel Workbook named “Business Name _Date Range”.`,
        ],
    },
    {
        name: 'PrimePay',
        title: 'Instructions for Generating Payroll Report from PrimePay:',
        subtitle: 'To download accurate payroll data from PrimePay, follow the steps below:',
        lines: [
            'Go to Reports',
            'Select Data view and Exports',
            'Choose Gross Amount by Employee on the drop down',
            'Select Custom date range: 03/13/2020 - 12/31/2020',
            'Select Options Tab',
            'Remove: Department, Employee Number',
            'Ensure following fields are select: First name, Last name, pay date, gross amount',
            'Select run report by .CSV',
            'Repeat steps for custom range 01/01/2021 - 09/30/2021',
        ],
    },
];
