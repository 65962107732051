import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    /** notification box */
    notificationBox: {
        borderRadius: 5,
        minWidth: 380,
        zIndex: 9999,
    },

    notificationList: {
        maxHeight: '40vh',
        overflowY: 'auto',
    },
    notificationHeader: {
        padding: '17px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-betwen',
        borderBottom: '1px solid #ffffff50',
        gap: 8,
    },
    notificationPrimary: {
        fontSize: 12,
        fontWeight: '700',
    },
    notificationSecondary: {
        fontSize: 12,
        fontWeight: '500',
    },
    notificationIconContainer: {
        background: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        height: 40,
        width: 40,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    unread: {},
}));

export default useStyles;
