import { createTheme } from '@mui/material/styles';

export const AppColors = {
    Success: '#4AAF41',
    primary: '#3988B1',
    Error: '#f50057',
    OffWhite: 'rgba(255,255,255,0.5)',
};

// const palette = {};
// Object.keys(AppColors).forEach((key) => {
//     palette[key] = { main: AppColors[key], contrastText: '#fff' };
// });

const { breakpoints } = createTheme({});

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#116EEA',
        },
        secondary: {
            main: '#003443',
        },
        white: {
            main: '#fff',
        },
        black: {
            main: '#000',
        },
        warning: {
            main: '#d4d122',
        },
        text: {
            primary: '#071121',
        },
        background: {
            default: '#f0f3f6',
        },
    },
    typography: {
        fontFamily: `Inter, Roboto`,
        /** Normal */
        h1: {
            fontSize: 48,
            [breakpoints.down('sm')]: {
                fontSize: 29,
            },
        },
        h2: {
            fontSize: 36,
            [breakpoints.down('sm')]: {
                fontSize: 24,
            },
        },
        h3: {
            fontSize: 32,
            [breakpoints.down('sm')]: {
                fontSize: 24,
            },
        },
        h4: {
            fontSize: 26,
            [breakpoints.down('sm')]: {
                fontSize: 22,
            },
        },
        h5: {
            fontSize: 22,
            [breakpoints.down('sm')]: {
                fontSize: 18,
            },
        },
        h6: {
            fontSize: 20,
            [breakpoints.down('sm')]: {
                fontSize: 17,
            },
        },
        font1: {
            fontSize: 18,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 16,
            },
        },

        font2: {
            fontSize: 16,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 15,
            },
        },
        font3: {
            fontSize: 14,
            fontFamily: 'Montserrat',
            fontWeight: 200,

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 13,
            },
        },
        font4: {
            fontSize: 12,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 11,
            },
        },
        font5: {
            fontSize: 11,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 10,
            },
        },
    },

    components: {
        MuiFilledInput: {
            styleOverrides: {
                input: {
                    padding: '12px 12px',
                },
                root: {
                    backgroundColor: 'rgba(73,163,218,0.1)',
                    borderRadius: 6,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                },
            },
        },

        MuiContainer: {
            styleOverrides: {
                root: {
                    [breakpoints.down('sm')]: {
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                },
            },
        },
    },
    olShadow: ['0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'],
    shadows: ['none', ...Array.from(new Array(26)).fill('0 3px 20px #1d26260d', 0, 25)],
});

export default theme;
