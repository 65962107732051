import { makeStyles } from '@mui/styles';
import theme from 'theme';
import { HEADER_HEIGHT } from '../TopNav/styles';

export const drawerWidth = 300;
export const drawerClosedWidth = 100;

const useStyles = makeStyles(() => {
    return {
        drawer: {
            boxShadow: '0 64px 20px rgba(0,0,0,.1)',
            backgroundColor: '#2e2828 !important',
            border: 'none',
            color: 'rgba(255,255,255,0.7) !important',
            borderRadius: '0px !important',
            borderTopRightRadius: '15px !important',
            borderBottomRightRadius: '15px !important',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(0, 1),
            minHeight: HEADER_HEIGHT,
            backgroundColor: '#373333',
            boxShadow: 'rgb(0 0 0 / 15%) 0px 1px 10px 0px',
        },
        navTitle: {
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textTransform: 'uppercase',
        },
        navDot: {
            height: 40,
            display: 'flex',
            alignItems: 'center',
            '&:before': {
                display: 'block',
                content: '""',
                height: 8,
                width: 8,
                borderRadius: '50%',
                backgroundColor: 'rgba(255,255,255,0.7)',
                marginLeft: 18,
            },
        },
        navItems: {
            marginLeft: drawerClosedWidth / 2 - 26,

            '& > *:not(last-child)': {
                marginTop: theme.spacing(2),
            },
        },
        navItem: {
            display: 'flex',
            gap: 12,
            alignItems: 'center',
            cursor: 'pointer',

            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        navItemIcon: {
            boxShadow: '0 3px 12px #0000000a',
            backgroundColor: 'white',
            borderRadius: 6,
            padding: '12px 16px',
            color: '#2e2828',
        },
    };
});

export default useStyles;
