import React, { useRef, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { BreakdownListItem } from './BreakdownListItem/BreakdownListItem';
import { CheckCircle, Info, Warning } from '@mui/icons-material';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import ApiResource from 'integrations/api/api_resource';
import axios from 'axios';
import FullscreenDialog from 'components/Dialogs/FullscreenDialog/FullscreenDialog';
import CsvDownload from './Dialogs/CsvDownload';
import { TEXT } from 'utils/strings';
import dayjs from 'dayjs';
import { creditBreakdown } from 'utils/credit-breakdown';

export default function Home() {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [fsOpen, setFsOpen] = useState(false);
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));
    const [downloading, setDownloading] = useState(false);
    const networking = useRef();
    const dispatch = useDispatch();

    const breakdown = creditBreakdown(account);

    async function handleDownloadCSV() {
        const filename = 'csv';
        const path = 'payroll.documents.csv';

        if (networking.current) return;
        networking.current = true;
        setDownloading(filename);
        try {
            let url = `${ApiResource.endpoint}/portal/accounts/documents/${account.model.AccountableId}/${path}`;

            const {
                data: { link },
            } = await axios.get(url, {
                headers: { 'x-auth': user.authToken },
            });

            window.open(link, '_blank');
        } catch (error) {
            dispatch(
                setSnackbar({ message: checkError(error), severity: 'error' }),
            );
        }
        setDownloading(false);
        networking.current = false;
    }

    return (
        <Box className={classes.root}>
            <FullscreenDialog
                onClose={() => setFsOpen(false)}
                open={Boolean(fsOpen)}
            >
                {fsOpen}
            </FullscreenDialog>
            <Container maxWidth="xl">
                <Box className={classes.header}>
                    <Typography variant="h3">2020 & 2021 Credits</Typography>
                    {account?.payroll?.documents?.csv && (
                        <Tooltip title={TEXT.substantiated_credit}>
                            <IconButton
                                disabled={downloading}
                                onClick={() =>
                                    isSmall
                                        ? setFsOpen(
                                              <CsvDownload
                                                  onDownload={handleDownloadCSV}
                                              />,
                                          )
                                        : handleDownloadCSV()
                                }
                            >
                                <Info />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h3" fontWeight="500" mb={2}>
                                Eligible Quarters
                            </Typography>
                            <Box className={classes.row}>
                                <Typography
                                    variant="h2"
                                    fontWeight={'600'}
                                    color="success.main"
                                >
                                    $
                                    {breakdown.eligible.credit.both.numberWithCommas(
                                        2,
                                    )}
                                </Typography>
                                <CheckCircle color="success" />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h3" fontWeight="500" mb={2}>
                                Quarters Under Review
                            </Typography>
                            <Tooltip arrow title={TEXT.amount_under_review}>
                                <Box
                                    className={classes.row}
                                    sx={{ width: 'fit-content' }}
                                >
                                    <Typography
                                        variant="h2"
                                        fontWeight={'600'}
                                        color="warning.main"
                                    >
                                        $
                                        {breakdown.potential.credit.both.numberWithCommas(
                                            2,
                                        )}
                                    </Typography>
                                    <Warning color="error" />
                                </Box>
                            </Tooltip>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h4" fontWeight="600">
                                2020 Breakdown
                            </Typography>
                            <Box className={classes.breakdownList}>
                                {['q22020', 'q32020', 'q42020'].map((q) => {
                                    const quarter = q.split('2020')[0];
                                    let { status, credit } = breakdown[q];
                                    if (q === 'q22020')
                                        credit += breakdown.q12020.credit;

                                    return (
                                        <BreakdownListItem
                                            value={credit}
                                            status={status}
                                            quarter={quarter}
                                            key={quarter}
                                        />
                                    );
                                })}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h4" fontWeight="600">
                                2021 Breakdown
                            </Typography>
                            <Box className={classes.breakdownList}>
                                {['q12021', 'q22021', 'q32021', 'q42021'].map(
                                    (q) => {
                                        if (
                                            q === 'q42021' &&
                                            account?.fields?.businessStart !==
                                                '2'
                                        )
                                            return null;

                                        const quarter = q.split('2021')[0];
                                        const { status, credit } = breakdown[q];

                                        return (
                                            <BreakdownListItem
                                                value={credit}
                                                status={status}
                                                quarter={quarter}
                                                key={quarter}
                                            />
                                        );
                                    },
                                )}
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {account?.fields?.pppLoans?.[0]?.amount && (
                            <Paper className={classes.paper}>
                                <Box className={classes.between}>
                                    <Typography variant="h4" fontWeight="600">
                                        2020 PPP Information
                                    </Typography>

                                    <Typography
                                        variant="font1"
                                        fontWeight={'600'}
                                    >
                                        {dayjs(
                                            account.fields.pppLoans[0].date,
                                        ).format('MMM DD, YYYY')}
                                    </Typography>
                                </Box>
                                <Box className={classes.breakdown}>
                                    <Box>
                                        <Typography
                                            variant="font1"
                                            fontWeight="600"
                                        >
                                            Forgiveness Period
                                        </Typography>
                                        <Typography>
                                            {
                                                account.fields.pppLoans[0]
                                                    .requirements
                                            }{' '}
                                            Weeks
                                        </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography
                                            variant="font1"
                                            fontWeight={'600'}
                                        >
                                            $
                                            {account.fields.pppLoans[0].amount.replace(
                                                '$',
                                                '',
                                            )}
                                        </Typography>
                                        <Typography>
                                            {account.fields.pppLoans[0]
                                                .percent ?? 60}
                                            % allocation
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {account?.fields?.pppLoans?.[1]?.amount && (
                            <Paper className={classes.paper}>
                                <Box className={classes.between}>
                                    <Typography variant="h4" fontWeight="600">
                                        2021 PPP Information
                                    </Typography>

                                    <Typography
                                        variant="font1"
                                        fontWeight={'600'}
                                    >
                                        {dayjs(
                                            account.fields.pppLoans[1].date,
                                        ).format('MMM DD, YYYY')}
                                    </Typography>
                                </Box>
                                <Box className={classes.breakdown}>
                                    <Box>
                                        <Typography
                                            variant="font1"
                                            fontWeight="600"
                                        >
                                            Forgiveness Period
                                        </Typography>
                                        <Typography>
                                            {
                                                account.fields.pppLoans[1]
                                                    .requirements
                                            }{' '}
                                            Weeks
                                        </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography
                                            variant="font1"
                                            fontWeight={'600'}
                                        >
                                            $
                                            {account.fields.pppLoans[1].amount.replace(
                                                '$',
                                                '',
                                            )}
                                        </Typography>
                                        <Typography>
                                            {account.fields.pppLoans[1]
                                                .percent ?? 60}
                                            % Allocation
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
