import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    items: {
        '& > div:not(:last-child)': {
            borderBottom: `1px solid #e2e2e2`,
        },
    },
    item: {
        overflow: 'hidden',
        padding: '18px 0px',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
    },
    itemTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 12,
    },
}));

export default useStyles;
