export function creditBreakdown(application) {
    const values = {
        q12020: { status: 'Pending', qualified: 0, credit: 0 },
        q22020: { status: 'Pending', qualified: 0, credit: 0 },
        q32020: { status: 'Pending', qualified: 0, credit: 0 },
        q42020: { status: 'Pending', qualified: 0, credit: 0 },
        q12021: { status: 'Pending', qualified: 0, credit: 0 },
        q22021: { status: 'Pending', qualified: 0, credit: 0 },
        q32021: { status: 'Pending', qualified: 0, credit: 0 },
        q42021: { status: 'Pending', qualified: 0, credit: 0 },
        total: {
            qualified: { both: 0, [2020]: 0, [2021]: 0 },
            credit: { both: 0, [2020]: 0, [2021]: 0 },
        },
        ineligible: {
            qualified: { both: 0, [2020]: 0, [2021]: 0 },
            credit: { both: 0, [2020]: 0, [2021]: 0 },
        },
        eligible: {
            qualified: { both: 0, [2020]: 0, [2021]: 0 },
            credit: { both: 0, [2020]: 0, [2021]: 0 },
        },
        potential: {
            qualified: { both: 0, [2020]: 0, [2021]: 0 },
            credit: { both: 0, [2020]: 0, [2021]: 0 },
        },
    };
    if (!application) return values;

    let quarters = ['q12020', 'q22020', 'q32020', 'q42020'];
    let qualifiedKeys = [
        'qualifiedWages2020Q1',
        'qualifiedWages2020Q2',
        'qualifiedWages2020Q3',
        'qualifiedWages2020Q4',
    ];
    let creditKeys = [
        'credit2020Q1',
        'credit2020Q2',
        'credit2020Q3',
        'credit2020Q4',
    ];
    loop({
        values,
        quarters,
        qualifiedKeys,
        creditKeys,
        year: '2020',
        application,
    });

    quarters = ['q12021', 'q22021', 'q32021', 'q42021'];
    qualifiedKeys = [
        'qualifiedWages2021Q1',
        'qualifiedWages2021Q2',
        'qualifiedWages2021Q3',
        'qualifiedWages2021Q4',
    ];
    creditKeys = [
        'credit2021Q1',
        'credit2021Q2',
        'credit2021Q3',
        'credit2021Q4',
    ];
    loop({
        values,
        quarters,
        qualifiedKeys,
        creditKeys,
        year: '2021',
        application,
    });

    return values;
}

function loop({
    values,
    quarters,
    qualifiedKeys,
    creditKeys,
    year,
    application,
}) {
    const qualificationMatrix = application.fields.qualificationMatrix;
    const payroll = application.documents.processor;

    for (let i = 0; i < quarters.length; i++) {
        const quarter = quarters[i];
        if (quarter === 'q42021' && application?.fields?.businessStart !== '2')
            continue;
        const qKey = qualifiedKeys[i];
        const cKey = creditKeys[i];
        let status = qualificationMatrix?.[quarter]?.value;
        if (quarter === 'q12020') status = qualificationMatrix?.q22020?.value;
        if (!status) continue;

        const qValue = payroll?.details?.[qKey] ?? 0;
        const cValue = payroll?.details?.[cKey] ?? 0;
        // qualified
        values.total.qualified.both += qValue;
        values.total.qualified[year] += qValue;
        values[quarter].qualified = qValue;
        // credit
        values.total.credit.both += cValue;
        values.total.credit[year] += cValue;
        values[quarter].credit = cValue;
        // set status
        values[quarter].status = status;

        if (status?.includes?.('Ineligible')) {
            values.ineligible.qualified[year] += qValue;
            values.ineligible.qualified.both += qValue;

            values.ineligible.credit[year] += cValue;
            values.ineligible.credit.both += cValue;
        } else if (status?.includes?.('Eligible')) {
            values.eligible.qualified[year] += qValue;
            values.eligible.qualified.both += qValue;

            values.eligible.credit[year] += cValue;
            values.eligible.credit.both += cValue;
        } else if (status?.includes?.('Under Review')) {
            values.potential.qualified[year] += qValue;
            values.potential.qualified.both += qValue;

            values.potential.credit[year] += cValue;
            values.potential.credit.both += cValue;
        }
    }
}
