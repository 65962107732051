import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import ReceviedImg from 'assets/images/stock/received.png';

export default function Received() {
    const classes = useStyles();

    const navigate = useNavigate();

    function handleGo() {
        navigate('/referral');
    }

    return (
        <Box
            className={clsx(classes.root, classes.center)}
            sx={{ maxWidth: 600 }}
        >
            <Typography variant="h6" fontWeight={'600'} mb={2}>
                Your employee retention credit has been received!
            </Typography>
            <Typography variant="font1" fontWeight={'400'} mb={2}>
                Congratulations! You should be receiving your funds soon. Thank
                you for giving us the opportunity to work with you.
            </Typography>
            <Typography variant="font1" fontWeight={'400'}>
                If you know of any other businesses which may be eligible for
                the ERC, you can refer them{' '}
                <span
                    className="bold"
                    style={{ cursor: 'pointer' }}
                    onClick={handleGo}
                >
                    here
                </span>
                .
            </Typography>

            <Box className={classes.imgContainer} mt={3}>
                <img src={ReceviedImg} className={classes.img} />
            </Box>
        </Box>
    );
}
