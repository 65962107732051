import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },

    blue: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
    },
    red: {
        backgroundColor: `${colors.red[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.red[600]} !important`,
        },
    },
    orange: {
        backgroundColor: `${colors.orange[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.orange[600]} !important`,
        },
    },
    yellow: {
        backgroundColor: `${colors.yellow[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.yellow[600]} !important`,
        },
    },
    green: {
        backgroundColor: `${colors.green[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.green[600]} !important`,
        },
    },
    purple: {
        backgroundColor: `${colors.purple[700]} !important`,
        color: 'white',
        '&:hover': {
            backgroundColor: `${colors.purple[600]} !important`,
        },
    },

    section: {
        padding: `${theme.spacing(2)} 0px`,
        borderBottom: '1px solid #e2e2e2',
    },
    sigContainer: {
        width: '100%',
    },
    signature: {
        border: '1px solid #b9b9b9',
        borderRadius: 3,
    },
}));

export default useStyles;
