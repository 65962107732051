import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import { LoadingButton } from '@mui/lab';
import { TEXT } from 'utils/strings';

export default function CsvDownload({ onDownload }) {
    const classes = useStyles();
    const [downloading, setDownloading] = useState(false);

    async function handleDownloadCSV() {
        setDownloading(true);
        await onDownload().catch(console.log);
        setDownloading(false);
    }

    return (
        <Box className={classes.root}>
            <Typography mb={2}>{TEXT.substantiated_credit}</Typography>
            <LoadingButton
                loading={downloading}
                variant="contained"
                fullWidth
                onClick={handleDownloadCSV}
            >
                Download CSV
            </LoadingButton>
        </Box>
    );
}
