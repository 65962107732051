import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Card from 'components/Card/Card';

export default function SEInformation() {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));

    return (
        <Box className={classes.root} component={Container} maxWidth={'lg'}>
            <Card title="My Information">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font1" fontWeight="500" mb={1}>
                            Contact
                        </Typography>
                        <Typography variant="font2" fontWeight="300">
                            {account?.firstName} {account?.lastName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font1" fontWeight="500" mb={1}>
                            Phone Number
                        </Typography>
                        <Typography variant="font2" fontWeight="300">
                            {account?.phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font1" fontWeight="500" mb={1}>
                            Email
                        </Typography>
                        <Typography variant="font2" fontWeight="300">
                            {account?.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font1" fontWeight="500" mb={1}>
                            Address
                        </Typography>
                        <Typography variant="font2" fontWeight="300">
                            {account?.fields.address} {account?.fields.state} {account?.fields.city} {account?.fields.zip}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
            <Card title="My Consultant" sx={{ marginTop: 3 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font1" fontWeight="500" mb={1}>
                            Consultant name
                        </Typography>
                        <Typography variant="font2" fontWeight="300">
                            {account?.User?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font1" fontWeight="500" mb={1}>
                            Consultant contact phone
                        </Typography>
                        <Typography variant="font2" fontWeight="300">
                            {account?.User?.phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font1" fontWeight="500" mb={1}>
                            Consultant Email
                        </Typography>
                        <Typography variant="font2" fontWeight="300">
                            {account?.User?.email}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
}
