import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import CalculatingImg from 'assets/images/stock/calculating.png';
import { Link } from 'react-router-dom';

export default function Calculating({ statusIndex, indexes }) {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));

    return (
        <Box className={clsx(classes.root, classes.center)} sx={{ maxWidth: 500 }}>
            {statusIndex < indexes?.signatures && (
                <>
                    <Typography variant="h6" fontWeight={'600'} mb={2}>
                        Your credit is being calculated
                    </Typography>
                    <Typography variant="font1" fontWeight={'400'} mb={2}>
                        We’re calculating your employee retention credit using the documentation you’ve provided and substantiating your qualification for each
                        quarter of eligibility.
                    </Typography>
                    <Typography variant="font1" fontWeight={'400'}>
                        Once complete, your consultant will contact you to discuss your employee retention credit calculation.
                    </Typography>
                </>
            )}
            {statusIndex >= indexes?.signatures && (
                <>
                    <Typography variant="h6" fontWeight={'600'} mb={2}>
                        Your credit has been calculated!
                    </Typography>
                    <Typography variant="font1" fontWeight={'400'} mb={2}>
                        See your employee retention credit breakdown for each eligible quarter in 2020 & 2021.
                    </Typography>
                    <Button variant="contained" component={Link} to="/">
                        View my credit
                    </Button>
                </>
            )}
            {/* <Box className={classes.imgContainer} mt={3}>
                <img src={CalculatingImg} className={classes.img} />
            </Box> */}
        </Box>
    );
}
