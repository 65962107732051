import React from 'react';
// UI
import { Box, Typography } from '@mui/material';
// images
import useStyles from './styles';

export default function OtherDetails() {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography
                variant="font2"
                sx={{ fontWeight: '400', marginTop: 1, marginBottom: 3 }}
            >
                If your consultant requests any other documents, please upload
                them here
            </Typography>
        </Box>
    );
}
