import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    title: {
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid #E4E4E4`,
    },
    paper: {
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        borderRadius: 4,
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(2),
        },
    },
}));

export default useStyles;
