import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        padding: theme.spacing(2),
        borderRadius: 4,
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 12,
        },
    },

    report: {
        display: 'flex',
        alignItems: 'center',
        gap: 14,
        color: 'white',
        width: 'fit-content',
        padding: theme.spacing(3),
        borderRadius: 24,
        cursor: 'pointer',
        background: 'linear-gradient(135deg, #0064C0 0%, #3391D0 100%)',

        '& > h5': {
            maxWidth: 220,
        },
    },
    disabledReport: {
        background: '#9e9e9e',
        cursor: 'not-allowed',
    },
    reportIcon: {
        height: 50,
        width: 50,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        '& > svg': {
            color: theme.palette.primary.main,
            fontSize: 30,
        },
    },
}));

export default useStyles;
