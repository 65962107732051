import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Formatter from 'utils/phone-formatter';

export default function Onboard() {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));

    return (
        <Box className={classes.root}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Consultant
            </Typography>
            <Box className={classes.stat} mb={2}>
                <Typography variant="font2" mb={0.5}>
                    Name
                </Typography>
                <Typography variant="font1" fontWeight={'600'}>
                    {account?.User?.name}
                </Typography>
            </Box>
            <Box className={classes.stat} mb={2}>
                <Typography variant="font2" mb={0.5}>
                    Email
                </Typography>
                <Typography variant="font1" fontWeight={'600'}>
                    {account?.User?.email}
                </Typography>
            </Box>
            <Box className={classes.stat} mb={2}>
                <Typography variant="font2" mb={0.5}>
                    Phone
                </Typography>
                <Typography
                    variant="font1"
                    component="a"
                    className="link"
                    href={account?.User?.phone}
                    fontWeight={'600'}
                >
                    {Formatter.formatPhone(
                        account?.User?.phone,
                        'NNN-NNN-NNNN',
                    )}
                </Typography>
            </Box>
        </Box>
    );
}
