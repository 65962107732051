import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import dayjs from 'dayjs';

export default function ClaimSent() {
    const classes = useStyles();
    const { user, account } = useSelector(({ user, account }) => ({
        user,
        account,
    }));
    const dispatch = useDispatch();
    const [updates, setUpdates] = useState({ items: [], count: 0 });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUpdates();
    }, [account.id]);

    async function getUpdates() {
        setLoading(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/portal/updates/${account.model.AccountableId}`, {
                headers: {
                    'x-auth': user.authToken,
                },
            });
            setUpdates(response.data);
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setLoading(false);
    }

    return (
        <Box className={clsx(classes.root, classes.center)}>
            <Typography variant="h6" fontWeight={'600'} mb={2}>
                Your employee retention credit has been sent to the IRS for processing.
            </Typography>
            <Typography variant="font1" fontWeight={'400'} mb={4}>
                Check back for updates on the status of your refund.
            </Typography>
            <Typography variant="font1" fontWeight={'600'} mb={1}>
                See Updates Below
            </Typography>

            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Agent #</TableCell>
                            <TableCell align="left">Notes</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                                Call Length
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {updates?.items?.map?.((row, i) => (
                            <TableRow
                                key={`idx-${i}`}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row?.meta?.agent}
                                </TableCell>
                                <TableCell align="left" sx={{ whiteSpace: 'pre-wrap' }}>
                                    {row?.meta?.body}
                                </TableCell>
                                <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                                    {dayjs(row?.createdAt).format('MMM DD YYYY')}
                                </TableCell>
                                <TableCell align="left" component="th" scope="row">
                                    {row?.meta?.callLength}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
