import React, { useEffect, useRef } from 'react';

// Modules
import { useDispatch, useSelector } from 'react-redux';
import { createEvent } from 'integrations/api/events';
import { setSnackbar } from 'redux/actions/snackbar';
import AccountRedux from 'redux/actions/account';
import NotificationsRedux from 'redux/actions/notifications';
// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import Navigation from 'components/Navigation/Navigation';
import FullscreenLoader from 'components/Widgets/FullscreenLoader';
import checkError from 'utils/check-error';
// Containers ERC
import ERCHome from './ERC/ERCHome/ERCHome';
import Information from './ERC/Information/Information';
import Status from './ERC/Status/Status';
import Documents from './ERC/Documents/Documents';
import Upload from './ERC/Upload/Upload';
// Container SE
import Affidavit from './SelfEmployment/Affidavit/Affidavit';
// Both
import Referral from './Referral/Referral';
import Notifications from './Notifications/Notifications';
import SEHome from './SelfEmployment/SEHome/SEHome';
import SEInformation from './SelfEmployment/SEInformation/SEInformation';
import SEUpload from './SelfEmployment/SEUpload/SEUpload';
import QualificationMatrix from './ERC/QualificationMatrix/QualificationMatrix';
import QualificationMatrixPlaceholder from './ERC/QualificationMatrix/QualificationMatrixPlaceHolder';
import Expedite from './ERC/Expedite/Expedite';
import VisaUpload from './VisaSettlement/VisaUpload/VisaUpload';

const AuthStack = (props) => {
    const dispatch = useDispatch();
    const networking = useRef({});
    const { user, accounts, account } = useSelector(({ user, accounts, account }) => ({
        user,
        accounts,
        account,
    }));

    useEffect(() => {
        createEvent('Portal Access', {}, user.authToken);
    }, []);

    useEffect(() => {
        async function getFirstAccount() {
            if (networking.current.account) return;
            networking.current.account = true;
            const firstAccount = accounts[0];
            try {
                await dispatch(AccountRedux.get(firstAccount));
            } catch (error) {
                console.log(error);
            }
            networking.current.account = false;
        }

        if (accounts.length > 0 && !account) getFirstAccount();
    }, [accounts]);

    useEffect(() => {
        async function getNotifications() {
            if (networking.current.notis) return;
            networking.current.notis = true;
            try {
                await dispatch(NotificationsRedux.get());
            } catch (error) {
                console.log(error);
                dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
            }
            networking.current.notis = false;
        }

        if (account) getNotifications();
    }, [account]);

    if (!account) return <FullscreenLoader />;

    return (
        <Navigation>
            <Routes>
                {account.type === 'ERC' && (
                    <>
                        <Route path="/" element={<ERCHome />} />
                        <Route path="/info" element={<Information />} />
                        <Route path="/status" element={<Status />} />
                        <Route path="/documents" element={<Documents />} />
                        <Route path="/upload" element={<Upload />} />
                        <Route path="/expedite" element={<Expedite />} />
                        <Route
                            path="/qualification-matrix"
                            element={account.metadata.showQualMatrix ? <QualificationMatrix /> : <QualificationMatrixPlaceholder />}
                        />
                    </>
                )}
                {account.type === 'self-employment' && (
                    <>
                        <Route path="/" element={<Affidavit />} />
                        <Route path="/credit" element={<SEHome />} />
                        <Route path="/info" element={<SEInformation />} />
                        <Route path="/upload" element={<SEUpload />} />
                    </>
                )}
                {account.type === 'visa-settlement' && (
                    <>
                        <Route path="/" element={<VisaUpload />} />
                    </>
                )}

                <Route path="/referral" element={<Referral />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="*" element={<Navigate to="/" replace />} />
                {/* End Routes */}
            </Routes>
        </Navigation>
    );
};

export default AuthStack;
