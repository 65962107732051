import React from 'react';
// UI
import { Box, Typography } from '@mui/material';
// images
import useStyles from './styles';

export default function RevenueDetails() {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography
                variant="font2"
                sx={{ fontWeight: '400', marginTop: 1, marginBottom: 3 }}
            >
                Please provide documentation of quarterly revenue from 2019,
                2020, and 2021 (12 quarters). Examples of this could include:
                Quarterly Profit & Loss statements, Gross Receipts
                documentation, Bank Statements
            </Typography>
        </Box>
    );
}
