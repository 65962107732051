import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import AccountRedux from 'redux/actions/account';

export default function DisclaimerDialog() {
    const classes = useStyles();
    const [agreed, setAgreed] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const { account } = useSelector(({ account }) => ({ account }));
    const dispatch = useDispatch();

    async function handleClose() {
        if (!agreed) return setError(true);
        setSaving(true);
        try {
            await dispatch(
                AccountRedux.update({
                    [`metadata.portalDisclaimer`]: true,
                }),
            );
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setSaving(false);
    }

    return (
        <Dialog
            open={!account.metadata.portalDisclaimer}
            classes={{ paper: classes.paper }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
                Disclaimer
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    I, {account.firstName} {account.lastName}, understand to claim Credits, I must sign an Affidavit which substantiates my eligibility. I
                    hereby agree to being truthful and accurate on all affidavits provided to me. I understand that it is my responsibility to verify the
                    accuracy of the information I provide. I understand that if any information is inaccurate, I may be subject to penalties. If at any time I
                    become aware that I have provided false information then I understand I must inform my Consultant immediately in writing.
                </DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={agreed}
                            onChange={(e) => {
                                setError(false);
                                setAgreed(e.target.checked);
                            }}
                        />
                    }
                    label={<Typography color={error ? 'error' : null}>I agree</Typography>}
                />
                <LoadingButton loading={saving} variant="contained" gradient onClick={handleClose} autoFocus>
                    Proceed
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
