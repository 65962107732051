import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    actionContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fieldSet: {
        width: '100%',
        textAlign: 'left',
    },

    logo: {
        maxWidth: 400,

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}));

export default useStyles;
