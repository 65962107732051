import React, { useState } from 'react';
//mods
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import ApiResource from 'integrations/api/api_resource';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useDispatch } from 'react-redux';
//comps
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Lottie
import Lottie from 'lottie-react';
import SuccessAnimation from 'assets/animations/party-success.json';
//styles
import Logo from 'assets/images/logos/logo-long-dark.png';
import useStyles from './styles';
import { Fade } from 'react-reveal';

export default function Login() {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const [state, setState] = useState(0);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function handleLogin(data) {
        setSubmitting(true);
        try {
            const email = data.email.toLowerCase();
            await axios.get(
                `${ApiResource.endpoint}/v2/authentication/${email}?to=portal`,
            );

            setState(1);
        } catch (error) {
            dispatch(
                setSnackbar({ severity: 'error', message: checkError(error) }),
            );
        }
        setSubmitting(false);
    }

    if (state === 1) {
        return (
            <Box className={classes.actionContent}>
                <Lottie
                    loop={false}
                    animationData={SuccessAnimation}
                    className={classes.animation}
                />
                <Typography
                    sx={{ marginTop: 4, fontWeight: '600' }}
                    variant="h2"
                >
                    Log in link sent!
                </Typography>
                <Typography
                    sx={{ textAlign: 'center', marginBottom: 4 }}
                    variant="font1"
                >
                    An email containing a link was sent to your email
                </Typography>
                <LoadingButton
                    loading={submitting}
                    onClick={handleSubmit(handleLogin)}
                >
                    Didn't receive a link? Click here to resend.
                </LoadingButton>
            </Box>
        );
    }

    return (
        <Box
            className={classes.actionContent}
            component="form"
            onSubmit={handleSubmit(handleLogin)}
        >
            <img src={Logo} className={classes.logo} />
            <Typography variant="font1" sx={{ my: 2 }}>
                Client Dashboard Login
            </Typography>
            <Box className={classes.fieldSet} sx={{ marginBottom: 2 }}>
                <Typography variant="font1" mb={0.5}>
                    Email Address
                </Typography>
                <TextField
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    size="small"
                    fullWidth
                    placeholder="user@example.com"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email', {
                        required: 'Invalid email address',
                        validate: (value) => validator.isEmail(value),
                    })}
                />
            </Box>
            <input type="submit" style={{ display: 'none' }} />

            <LoadingButton
                fullWidth
                variant="contained"
                loading={submitting}
                onClick={handleSubmit(handleLogin)}
            >
                Sign In
            </LoadingButton>
        </Box>
    );
}
