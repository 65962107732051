import dayjs from 'dayjs';
import { SE_REASONS } from 'utils/constants';

export const CALENDARS = {
    period1: [
        { minDate: dayjs('04/01/2020'), maxDate: dayjs('04/30/2020') },
        { minDate: dayjs('05/01/2020'), maxDate: dayjs('05/31/2020') },
        { minDate: dayjs('06/01/2020'), maxDate: dayjs('06/30/2020') },
        { minDate: dayjs('07/01/2020'), maxDate: dayjs('07/31/2020') },
        { minDate: dayjs('08/01/2020'), maxDate: dayjs('08/31/2020') },
        { minDate: dayjs('09/01/2020'), maxDate: dayjs('09/30/2020') },
        { minDate: dayjs('10/01/2020'), maxDate: dayjs('10/31/2020') },
        { minDate: dayjs('11/01/2020'), maxDate: dayjs('11/30/2020') },
        { minDate: dayjs('12/01/2020'), maxDate: dayjs('12/31/2020') },
        { minDate: dayjs('01/01/2021'), maxDate: dayjs('01/31/2021') },
        { minDate: dayjs('02/01/2021'), maxDate: dayjs('02/28/2021') },
        { minDate: dayjs('03/01/2021'), maxDate: dayjs('03/31/2021') },
    ],
    period2: [
        { minDate: dayjs('04/01/2021'), maxDate: dayjs('04/30/2021') },
        { minDate: dayjs('05/01/2021'), maxDate: dayjs('05/31/2021') },
        { minDate: dayjs('06/01/2021'), maxDate: dayjs('06/30/2021') },
        { minDate: dayjs('07/01/2021'), maxDate: dayjs('07/31/2021') },
        { minDate: dayjs('08/01/2021'), maxDate: dayjs('08/31/2021') },
        { minDate: dayjs('09/01/2021'), maxDate: dayjs('09/30/2021') },
    ],
};

export const EXTRA_STEPS = [
    {
        title: 'W2 Leave',
        dates: 'April 1st 2020 - September 30th 2021',
        calendar: [...CALENDARS.period1, ...CALENDARS.period2],
        part: 'extra',
        key: 'w2leave',
        type: 'w2',
        requirement: 'metadata.w2SickLeave',
        periodColor: 'grey',
        color: 'grey',
    },
    // {
    //     title: 'Unemployment Leave',
    //     dates: 'April 1st 2020 - September 30th 2021',
    //     calendar: [...CALENDARS.period1, ...CALENDARS.period2],
    //     part: 'extra',
    //     key: 'unemployment',
    //     type: 'unemployment',
    //     requirement: 'metadata.unemploymentClaim',
    //     periodColor: 'grey',
    //     color: 'grey',
    // },
];

export const STEPS = [
    {
        title: 'Sick Leave',
        dates: 'April 1st 2020 - March 31st 2021',
        reasons: SE_REASONS.selfPeriod1,
        maxDays: 10,
        calendar: CALENDARS.period1,
        disabledDates: ['unemployment', 'w2leave', 'familyleave1', 'childleave1'],
        key: 'sickleave1',
        type: 'sick',
        periodColor: 'blue',
        color: 'blue',
        part: 'part1',
    },
    {
        title: 'Family Leave',
        dates: 'April 1st 2020 - March 31st 2021',
        reasons: SE_REASONS.familyPeriod1,
        maxDays: 10,
        calendar: CALENDARS.period1,
        skip: true,
        addDaysFrom: 'sickleave1',
        disabledDates: ['unemployment', 'w2leave', 'sickleave1', 'childleave1'],
        key: 'familyleave1',
        type: 'family',
        periodColor: 'blue',
        color: 'blue',
        part: 'part1',
    },
    {
        title: 'Sick Leave',
        dates: 'April 1st 2021 - September 30th 2021',
        reasons: SE_REASONS.selfPeriod2,
        maxDays: 10,
        calendar: CALENDARS.period2,
        disabledDates: ['unemployment', 'w2leave', 'familyleave2', 'childleave2'],
        key: 'sickleave2',
        type: 'sick',
        periodColor: 'yellow',
        color: 'yellow',
        part: 'part2',
    },
    {
        title: 'Family Leave',
        dates: 'April 1st 2021 - September 30th 2021',
        reasons: SE_REASONS.familyPeriod2,
        maxDays: 10,
        calendar: CALENDARS.period2,
        skip: true,
        addDaysFrom: 'sickleave2',
        disabledDates: ['unemployment', 'w2leave', 'sickleave2', 'childleave2'],
        type: 'family',
        periodColor: 'yellow',
        key: 'familyleave2',
        color: 'yellow',
        part: 'part2',
    },
    {
        title: 'Child Care Leave',
        dates: 'April 1st 2020 - March 31st 2021',
        reasons: SE_REASONS.childPeriod1,
        maxDays: 50,
        calendar: CALENDARS.period1,
        disabledDates: ['unemployment', 'w2leave', 'sickleave1', 'familyleave1'],
        key: 'childleave1',
        type: 'child',
        periodColor: 'blue',
        color: 'blue',
        part: 'part1',
    },
    {
        title: 'Child Care Leave',
        dates: 'April 1st 2021 - September 30th 2021',
        reasons: SE_REASONS.childPeriod2,
        maxDays: 60,
        calendar: CALENDARS.period2,
        disabledDates: ['unemployment', 'w2leave', 'sickleave2', 'familyleave2'],
        key: 'childleave2',
        type: 'child',
        periodColor: 'yellow',
        color: 'yellow',
        part: 'part2',
    },
    {
        key: 'signature',
    },
];

export function transformData(dates, reasons, type, part, skip, paidPerDay) {
    const affidavitForm = {};

    if (skip) {
        affidavitForm[`${part}_${type}_dates`] = [];
        affidavitForm[`${part}_${type}_reasons`] = [];
        affidavitForm[`${part}_qualify_${type}_leave`] = false;
    } else {
        affidavitForm[`${part}_${type}_dates`] = dates?.map?.((date) => date?.format?.('MM/DD/YY') ?? date) ?? [];
        affidavitForm[`${part}_${type}_reasons`] = reasons || [];
        affidavitForm[`${part}_qualify_${type}_leave`] = true;
    }

    if (type === 'w2') {
        affidavitForm['w2SickPaidPerDay'] = paidPerDay;
    }

    return affidavitForm;
}

export function transformDates(application) {
    return {
        sickleave1: application?.forms?.affidavit?.part1_sick_dates || [],
        familyleave1: application?.forms?.affidavit?.part1_family_dates || [],
        childleave1: application?.forms?.affidavit?.part1_child_dates || [],
        sickleave2: application?.forms?.affidavit?.part2_sick_dates || [],
        familyleave2: application?.forms?.affidavit?.part2_family_dates || [],
        childleave2: application?.forms?.affidavit?.part2_child_dates || [],
        w2leave: application?.forms?.affidavit?.extra_w2_dates || [],
        unemployment: application?.forms?.affidavit?.extra_unemployment_dates || [],
    };
}
export function transformReasons(application) {
    return {
        sickleave1: application?.forms?.affidavit?.part1_sick_reasons || [],
        familyleave1: application?.forms?.affidavit?.part1_family_reasons || [],
        childleave1: application?.forms?.affidavit?.part1_child_reasons || [],
        sickleave2: application?.forms?.affidavit?.part2_sick_reasons || [],
        familyleave2: application?.forms?.affidavit?.part2_family_reasons || [],
        childleave2: application?.forms?.affidavit?.part2_child_reasons || [],
        w2leave: application?.forms?.affidavit?.extra_w2_reasons || [],
        unemployment: application?.forms?.affidavit?.extra_unemployment_reasons || [],
    };
}
