import React from 'react';
import { Box, Typography } from '@mui/material';
import samplePayroll from 'utils/data/sample-payroll';
import useStyles from './styles';
export default function SamplePayroll({ sx }) {
    const classes = useStyles();
    return (
        <Box className={classes.sample} sx={sx}>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <td>
                            <Typography sx={{ fontWeight: '600' }}>
                                Employee Name
                            </Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontWeight: '600' }}>
                                Gross Wages
                            </Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontWeight: '600' }}>
                                Health Plan Expenses
                            </Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontWeight: '600' }}>
                                R&D
                            </Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontWeight: '600' }}>
                                Check Date
                            </Typography>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {samplePayroll.map((row, i) => {
                        return (
                            <tr key={`${row.name}-${i}`}>
                                <td>
                                    <Typography>{row.name}</Typography>
                                </td>
                                <td>
                                    <Typography>{row.wage}</Typography>
                                </td>
                                <td>
                                    <Typography>{row.healthcare}</Typography>
                                </td>
                                <td>
                                    <Typography>{row.rnd || 0}</Typography>
                                </td>
                                <td>
                                    <Typography>{row.date}</Typography>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Box>
    );
}
