import React, { useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import AccountRedux from 'redux/actions/account';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Lottie
import Lottie from 'lottie-react';
import CookingPot from 'assets/animations/cooking-pot.json';
import { LoadingButton } from '@mui/lab';

export default function AwaitingConsultant({ onBack }) {
    const classes = useStyles();
    const { account, user } = useSelector(({ account, user }) => ({ account, user }));
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();

    async function handleRefresh() {
        setSaving(true);
        try {
            await dispatch(AccountRedux.current());
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setSaving(false);
    }

    return (
        <Container maxWidth="lg">
            <Card titleProps={{ fontWeight: '600', variant: 'font1' }} title="Please wait while your consultant finalizes your application">
                <Box className={classes.animationContainer}>
                    <Lottie animationData={CookingPot} loop className={classes.animation} />
                </Box>
                <Box className={classes.action}>
                    <LoadingButton loading={saving} fullWidth variant="contained" onClick={handleRefresh}>
                        Click to Refresh
                    </LoadingButton>
                    <LoadingButton fullWidth onClick={onBack}>
                        Edit Application
                    </LoadingButton>
                </Box>
            </Card>
        </Container>
    );
}
