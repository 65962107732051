import * as React from 'react';
// Modules
import { drawerClosedWidth } from './SideNav/styles';
// components
import { Box, Container, Hidden, Typography } from '@mui/material';
import SideNav from './SideNav/SideNav';
import TopNav from './TopNav/TopNav';
import BottomNav from './BottomNav/BottomNav';
import ChangeAccountButton from 'components/Buttons/ChangeAccountButton/ChangeAccountButton';
import NotificationsList from './NotificationsList/NotificationsList';
import { Dashboard } from '@mui/icons-material';
// Styles
import useStyles from './styles';
import UserRedux from 'redux/actions/user';
import { useDispatch } from 'react-redux';

export default function Navigation({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    function handleLogout() {
        dispatch(UserRedux.signOut());
    }

    return (
        <Box className={classes.root}>
            <TopNav handleLogout={handleLogout} />
            <Hidden mdUp>
                <BottomNav />
            </Hidden>
            <Hidden mdDown>
                <SideNav handleLogout={handleLogout} />
            </Hidden>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    mt: { xs: '60px', md: 0 },
                    p: { xs: 1, md: 3 },
                    pt: { xs: 4, md: 0 },
                    pb: { xs: 10, md: 3 },
                    marginLeft: { xs: 0, md: `${drawerClosedWidth}px` },
                }}
            >
                <Hidden mdDown>
                    <Box sx={{ height: 96 }} />
                </Hidden>
                {children}
            </Box>
        </Box>
    );
}
