import { MdOutlineDocumentScanner } from 'react-icons/md';
import { BiLoader } from 'react-icons/bi';
import { CiBank } from 'react-icons/ci';
import { AppColors } from 'theme';

const NOTIFICATION_TYPE = {
    CreditUpdate: { icon: CiBank, color: AppColors.TextDark },
    IRSUpdate: { icon: BiLoader, color: AppColors.TextDark },
    DocumentsNeeded: { icon: MdOutlineDocumentScanner, color: AppColors.Yellow },
};

export function NotificationIcon({ type, ...props }) {
    const Icon = NOTIFICATION_TYPE?.[type]?.icon ?? Done;
    const color = NOTIFICATION_TYPE?.[type]?.color ?? AppColors.SeaGreen;

    return <Icon style={{ fill: color }} />;
}
