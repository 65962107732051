import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    /** Form styles */
    formRow: {
        display: 'flex',
        alignItems: 'center',
    },
    gridContainer: {
        '& > div': {
            height: '100%',
        },
    },
    borderLeft: {
        borderTop: '2px solid #000',
        borderRight: '2px solid #000',
    },
    borderMiddle: {
        borderTop: '2px solid #000',
    },
    borderRight: {
        borderTop: '2px solid #000',
        borderLeft: '2px solid #000',
    },
    borderTop: {
        borderTop: '2px solid #000',
    },
    lineRow: {
        height: 47,
        display: 'flex',
        alignItems: 'center',
    },
    lineNumber: {
        height: 47,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '2px solid #000',
        borderBottom: '2px solid #000',
        borderRight: '2px solid #000',
    },
    lineField: {
        height: 47,
        borderBottom: '2px solid #000',
        borderRight: '2px solid #000',
        display: 'flex',
        alignItems: 'center',
    },
    lineCheck: {
        height: 47,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    formActions: {
        paddingTop: theme.spacing(4),
        borderTop: '2px solid #000',
        display: 'flex',
        gap: 12,
    },

    linesHeader: {
        boxSizing: 'border-box',
        padding: '18px',
        height: '130px',
        borderBottom: '2px solid #000',
    },

    list: {
        display: 'flex',
        flexDirection: 'column',

        '& > div:not(:last-child)': {
            borderBottom: '1px solid #efefef',
        },
    },
    listItem: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,

        '&:hover': {
            backgroundColor: '#efefef',
        },
    },

    columnGapSections: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },

    sigContainer: {
        width: '100%',
    },
    signature: {
        border: '1px solid #b9b9b9',
        borderRadius: 3,
    },

    actions: {
        marginTop: 16,
        paddingTop: 16,
        borderTop: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'justify-end',
    },
}));

export default useStyles;
