import { makeStyles } from '@mui/styles';
import theme from 'theme';
import { drawerClosedWidth } from '../SideNav/styles';

export const HEADER_HEIGHT = 80;
export const HEADER_HEIGHT_MD = 64;

const useStyles = makeStyles(() => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: HEADER_HEIGHT,
        marginLeft: drawerClosedWidth,

        [theme.breakpoints.down('md')]: {
            height: HEADER_HEIGHT_MD,
            marginLeft: 0,
        },
    },
    logo: {
        width: '180px',
    },
    rightContent: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,

        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            width: '100%',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    leftContent: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 0px 6px 18px',
        gap: 12,
        borderLeft: '1px solid #e5e5e6',
    },

    menuItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '8px 68px 8px 12px',
        gap: 8,

        '&:hover': {
            color: theme.palette.primary.main,

            '& > svg': {
                color: theme.palette.primary.main,
            },
        },
    },
    dropdownContent: {
        padding: '16px 0px',
    },
    profileDropdown: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    profilePicture: {
        height: 55,
        width: 55,
        borderRadius: '50%',

        [theme.breakpoints.down('sm')]: {
            height: 45,
            width: 45,
        },
    },
}));

export default useStyles;
