import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    listIcon: {
        fontSize: 24,
    },
    drawer: {
        paddingLeft: 12,
        paddingRight: 6,
        borderRight: 'none !important',
    },
    activeItem: {
        backgroundColor: '#EFEFEF',
        borderLeft: `12px solid ${theme.palette.secondary.main}`,
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        padding: '24px 16px',
        paddingLeft: 16,
    },
    inactiveItem: {
        padding: '24px 16px',
        paddingLeft: 28,
    },

    drawerHeader: {
        padding: 20,
        '& > img': {
            maxWidth: '200px',
        },
        borderBottom: `1px solid #efefef`,
    },

    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    toolbarHeader: {
        '& > img': {
            width: '120px',
        },
    },

    listItem: {
        display: 'block',
        display: 'flex',
        gap: 20,
        cursor: 'pointer',
    },
    name: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 0px 6px 18px',
        gap: 12,
        borderLeft: '1px solid #e5e5e6',
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },

    bottomBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 60,
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 0,
        zIndex: 99,
        width: '100%',
    },
}));

export default useStyles;
