import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import { Check, Close } from '@mui/icons-material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Formatter from 'utils/phone-formatter';
import { LINKS } from 'utils/constants';

export default function Documents() {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));

    return (
        <Box className={clsx(classes.root, classes.evenColumn)}>
            <Box>
                <Typography variant="h6" fontWeight={'600'} mb={2}>
                    Uploaded Documents
                </Typography>
                <Box className={classes.row} mb={2}>
                    <Typography variant="font1" fontWeight={'400'} component={Link} to="/upload">
                        Payroll
                    </Typography>
                    {account?.documents?.uploads?.payroll ? <Check color="success" /> : <Close color="error" />}
                </Box>
                <Box className={classes.row} mb={2}>
                    <Typography variant="font1" fontWeight={'400'} component={Link} to="/upload">
                        Revenue
                    </Typography>
                    {account?.documents?.uploads?.revenue ? <Check color="success" /> : <Close color="error" />}
                </Box>
                <Box className={classes.row} mb={2}>
                    <Typography variant="font1" fontWeight={'400'} component={Link} to="/upload">
                        941 Returns
                    </Typography>
                    {account?.documents?.uploads?.taxes ? <Check color="success" /> : <Close color="error" />}
                </Box>
                <Box className={classes.row} mb={2}>
                    <Typography variant="font1" fontWeight={'400'} component={Link} to="/upload">
                        Loan Documentation
                    </Typography>
                    {account?.documents?.uploads?.ppp1 || account?.documents?.uploads?.ppp2 ? <Check color="success" /> : <Close color="error" />}
                </Box>
                <Box className={classes.row} mb={2}>
                    <Typography variant="font1" fontWeight={'400'} component={Link} to="/upload">
                        Other Documents
                    </Typography>
                    {account?.documents?.uploads?.other && <Check color="success" />}
                </Box>
            </Box>
            <Box className={classes.actions} mt={4}>
                <Button component={Link} to="/upload" variant="contained" color="warning">
                    Upload more documents
                </Button>
                <Button component="a" target="_blank" href={LINKS.REQUIRED_DOCS} variant="contained" color="info">
                    Learn more about these documents
                </Button>
            </Box>
            <Typography variant="font2" fontWeight={'600'} sx={{ mt: { xs: 3, md: 2 } }}>
                Questions? Call your consultant{' '}
                <a className="link" href={`tel:${account?.User?.phone}`}>
                    {Formatter.formatPhone(account?.User?.phone, 'NNN-NNN-NNNN')}
                </a>{' '}
                ({account?.User?.name?.split?.(' ')?.[0]})
            </Typography>
        </Box>
    );
}
