import * as React from 'react';
// Modules
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ERCMenuItems, SEMenuItems, VisaMenuItems } from '../data';
// UI
import { Box, IconButton, Drawer as MuiDrawer, styled, Typography, Fade, Badge } from '@mui/material';
import { Menu } from '@mui/icons-material';
// images
import useStyles, { drawerClosedWidth, drawerWidth } from './styles';

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: '0.4s',
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: '0.4s',
    }),
    overflowX: 'hidden',
    width: drawerClosedWidth,
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export default function SideNav({ handleLogout }) {
    const [open, setOpen] = React.useState(false);
    const { user, account, notifications } = useSelector(({ user, account, notifications }) => ({ user, account, notifications }));
    const classes = useStyles(open);

    function handleDrawerClose() {
        setOpen(false);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    function handleAction(action) {
        if (action === 'logout') {
            handleLogout();
        }
    }

    const updates = {};
    notifications.items.forEach((item) => {
        if (item.type === 'CreditUpdate' && !item.readAt) {
            updates.credit = true;
        }
        if (item.type === 'IRSUpdate' && !item.readAt) {
            updates.status = true;
        }
    });

    const MenuItems =
        account.type === 'ERC' ? ERCMenuItems : account.type === 'self-employment' ? SEMenuItems : account.type === 'visa-settlement' ? VisaMenuItems : [];

    return (
        <Drawer variant="permanent" open={open} classes={{ paper: classes.drawer }} onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}>
            <Box className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    <Menu sx={{ color: 'white !important' }} />
                </IconButton>
            </Box>
            <Box className={classes.navItems} sx={{ py: 4 }}>
                {MenuItems.map((item, i) => {
                    if (item.require === 'metadata.showQualMatrix') {
                        if (!account.metadata.showQualMatrix) return;
                    } else if (item.require && !user?.[item.require]) return;

                    if (item.type === 'text') {
                        return !open ? (
                            <Box key={i} className={classes.navDot} />
                        ) : (
                            <Box key={i} component={Fade} in className={classes.navTitle}>
                                <Typography variant="font3">{item.title}</Typography>
                            </Box>
                        );
                    }

                    if (item.type === 'link') {
                        let path = item.to;

                        if (item.require) {
                            path = path.replace(':id', user[item.require]);
                        }

                        return (
                            <Box key={i} component={Link} to={item.to} className={classes.navItem}>
                                <Badge
                                    color="primary"
                                    badgeContent={
                                        item.title === 'Credit Detail' && updates.credit ? '!' : item.title === 'Refund Status' && updates.status ? '!' : null
                                    }
                                >
                                    <Box className={classes.navItemIcon}>
                                        <item.Icon />
                                    </Box>
                                </Badge>
                                {open && (
                                    <Fade in>
                                        <Typography>{item.title}</Typography>
                                    </Fade>
                                )}
                            </Box>
                        );
                    }

                    if (item.type === 'action') {
                        return (
                            <Box key={i} onClick={() => handleAction(item.action)} className={classes.navItem}>
                                <Box className={classes.navItemIcon}>
                                    <item.Icon />
                                </Box>
                                {open && (
                                    <Fade in>
                                        <Typography>{item.title}</Typography>
                                    </Fade>
                                )}
                            </Box>
                        );
                    }

                    return null;
                })}
            </Box>
        </Drawer>
    );
}
