import React from 'react';
// UI
import { Box, Typography } from '@mui/material';
// images
import useStyles from './styles';

export default function HealthcareDetails() {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography
                variant="font2"
                sx={{ fontWeight: '600', marginTop: 1, marginBottom: 2 }}
            >
                Please upload in .CSV format if possible
            </Typography>
            <Typography
                variant="font2"
                sx={{ fontWeight: '400', marginTop: 1, marginBottom: 3 }}
            >
                Please include a document that shows{' '}
                <span className="bold underline">Employer Side</span> Healthcare
                Plan expenses per employee, per paycheck. Similar to Payroll
                Breakdown
            </Typography>
        </Box>
    );
}
