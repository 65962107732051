import React from 'react';

// Modules
import { useDispatch } from 'react-redux';
// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthBg from 'assets/images/backgrounds/auth-bg.png';
import LoginImg from 'assets/images/stock/login.png';
import Start from './Start/Start';

import useStyles from './styles';
import { Box } from '@mui/material';
import Login from './Login/Login';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import Authenticate from './Authenticate/Authenticate';

const UnauthStack = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.imageContainer}>
                <img src={LoginImg} />
            </Box>

            <Box className={classes.actions}>
                <Routes>
                    <Route path="" element={<Login />} />
                    <Route path="start" element={<Start />} />
                    <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="authenticate" element={<Authenticate />} />
                    {/* End Routes */}

                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default UnauthStack;
