import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    sample: {
        padding: theme.spacing(2),
        border: `1px solid rgba(0,0,0,0.2)`,
        borderRadius: 3,
    },
}));

export default useStyles;
