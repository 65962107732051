import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    name: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    bottomBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 60,
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 0,
        zIndex: 99,
        width: '100%',
    },
}));

export default useStyles;
