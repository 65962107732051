import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
//mods
//comps
import { Alert, Box, Chip, Paper, Typography } from '@mui/material';
//styles
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Card from 'components/Card/Card';
import Linkify from 'linkify-react';
import { creditBreakdown } from 'utils/credit-breakdown';

const rows = [
    { key: 'q22020', title: '2020 Q2' },
    { key: 'q32020', title: '2020 Q3' },
    { key: 'q42020', title: '2020 Q4' },
    { key: 'q12021', title: '2021 Q1' },
    { key: 'q22021', title: '2021 Q2' },
    { key: 'q32021', title: '2021 Q3' },
    { key: 'q42021', title: '2021 Q4' },
];

export default function QualificationMatrix() {
    const classes = useStyles();
    const itemsRef = useRef();
    const [minDivHeight, setMinDivHeight] = useState(0);
    const { account } = useSelector(({ account }) => ({
        account,
    }));

    useLayoutEffect(() => {
        function findDivWithLargestHeight() {
            const parentDiv = itemsRef.current; // reference to the parent div (use ref or querySelector, for example)
            if (!parentDiv) return;
            const childDivs = parentDiv.querySelectorAll('div'); // Select all child divs

            let maxHeight = 0; // Initialize the maximum height
            let divWithLargestHeight = null;

            // Iterate through child divs
            childDivs.forEach((div) => {
                const divHeight = div.clientHeight; // Get the height of each div

                if (divHeight > maxHeight) {
                    maxHeight = divHeight; // Update the maximum height
                    divWithLargestHeight = div; // Store a reference to the div with the largest height
                }
            });

            setMinDivHeight(divWithLargestHeight.clientHeight); // Return the div with the largest height
        }

        findDivWithLargestHeight();
    }, []);

    function getColor(status) {
        if (status === 'Under Review') return 'warning';
        if (status === 'Ineligible') return 'error';
        if (status === 'Pending') return 'info';
        return 'success';
    }

    const breakdown = creditBreakdown(account);
    const values = {
        q22020: account.fields.qualificationMatrix?.q22020?.value || '',
        q22020explaination: account.fields.qualificationMatrix?.q22020?.explaination || '',
        q32020: account.fields.qualificationMatrix?.q32020?.value || '',
        q32020explaination: account.fields.qualificationMatrix?.q32020?.explaination || '',
        q42020: account.fields.qualificationMatrix?.q42020?.value || '',
        q42020explaination: account.fields.qualificationMatrix?.q42020?.explaination || '',
        q12021: account.fields.qualificationMatrix?.q12021?.value || '',
        q12021explaination: account.fields.qualificationMatrix?.q12021?.explaination || '',
        q22021: account.fields.qualificationMatrix?.q22021?.value || '',
        q22021explaination: account.fields.qualificationMatrix?.q22021?.explaination || '',
        q32021: account.fields.qualificationMatrix?.q32021?.value || '',
        q32021explaination: account.fields.qualificationMatrix?.q32021?.explaination || '',
        q42021: account.fields.qualificationMatrix?.q42021?.value || '',
        q42021explaination: account.fields.qualificationMatrix?.q42021?.explaination || '',
    };

    return (
        <Box className={classes.root}>
            <Card title="Qualification Matrix" sx={{ marginBottom: 2 }} className={classes.paper}>
                <Box ref={itemsRef} className={classes.items}>
                    {rows.map(({ title, key }, i) => {
                        if (!values[key].includes('Eligible')) return null;
                        let qualifiedAmount = breakdown?.[key].credit;
                        if (key === 'q22020') qualifiedAmount += breakdown.q22020.credit;

                        return (
                            <Box className={classes.item} key={i} sx={{ minHeight: { xs: 'unset', md: `${minDivHeight}px` } }}>
                                <Box>
                                    {key === 'q42021' && (
                                        <Typography variant="font3" color="error" fontWeight={'600'} mb={1}>
                                            (Note: Only available for companies who started after Feb 15th 2020, AKA Recovery Startup)
                                        </Typography>
                                    )}
                                    <Box className={classes.itemTitle} sx={{ mb: 2 }}>
                                        <Typography mb={1} variant="h4" fontWeight={'500'}>
                                            {title}
                                        </Typography>
                                        <Chip sx={{ fontSize: 16, mb: 1, minWidth: '150px' }} color={getColor(values[key])} label={values[key]} mb={0.5} />
                                    </Box>
                                </Box>

                                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                                    <Linkify options={{ target: '_blank', className: 'linkify' }}>{values[`${key}explaination`] || 'Not Specified'}</Linkify>
                                </Typography>

                                <Typography variant="h5" fontWeight={'500'} mt={2}>
                                    Amount Qualified: ${qualifiedAmount.numberWithCommas(2)}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Card>
        </Box>
    );
}
