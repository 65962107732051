import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Input, Radio, RadioGroup, Typography } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useTheme } from '@emotion/react';
import AccountRedux from 'redux/actions/account';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { LoadingButton } from '@mui/lab';
import { sortStringDates } from 'utils/sort-dates';
import { cloneDeep } from 'lodash';

const PARTS = [
    { part: 'part1_sick', title: 'Sick', period: 1, dates: 'April 1st 2020 - March 31st 2021' },
    { part: 'part1_family', title: 'Family', period: 1, dates: 'April 1st 2020 - March 31st 2021' },
    { part: 'part2_sick', title: 'Sick', period: 2, dates: 'April 1st 2021 - September 30th 2021' },
    { part: 'part2_family', title: 'Family', period: 2, dates: 'April 1st 2021 - September 30th 2021' },
    { part: 'part1_child', title: 'Child', period: 1, dates: 'April 1st 2020 - March 31st 2021' },
    { part: 'part2_child', title: 'Child', period: 2, dates: 'April 1st 2021 - September 30th 2021' },
];
const EXTRA_PARTS = [
    { part: 'extra_w2', title: 'W2', dates: 'April 1st 2020 - September 30th 2021', noText: 'No, I did not claim Sick Leave while on a W2 in ‘20 & ‘21.' },
    // {
    //     part: 'extra_unemployment',
    //     title: 'Unemployment',
    //     dates: 'April 1st 2020 - September 30th 2021',
    //     noText: 'No, I did not claim Unemployment Insurance or Pandemic Unemployment Assistance in ‘20 & ‘21.',
    // },
];

export default function Confirmation({ onBack }) {
    const classes = useStyles();
    // Refs
    const sigCanvas = useRef();
    // Variables
    const { account, user } = useSelector(({ account, user }) => ({ account, user }));
    const signed = Boolean(account?.forms?.affidavit?.signature);
    const readyToSign = Boolean(account?.metadata?.readyToSignAffidavit);
    const dispatch = useDispatch();
    const [fetching8821, setFetching8821] = useState(false);
    const [identification, setId] = useState(null);
    // State
    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        confirmed: signed ? true : '',
        proof: signed ? true : '',
        unemployment: account?.forms?.affidavit?.unemployment ? true : '',
        eight21: signed ? true : '',
    });
    const [radio, setRadio] = useState(signed ? 'yes' : '');

    useEffect(() => {
        if (account?.forms?.affidavit?.signature) {
            sigCanvas?.current?.clear?.();
            sigCanvas?.current?.fromDataURL?.(account?.forms?.affidavit?.signature);
        }

        if (!account.metadata.readyToSignAffidavit) {
            sigCanvas?.current?.off?.();
        } else sigCanvas?.current?.on?.();
    }, [account]);

    async function handleRefresh() {
        setSaving(true);
        try {
            await dispatch(AccountRedux.current());
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setSaving(false);
    }

    async function get8821() {
        setFetching8821(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/portal/applications/${account.model.AccountableId}/8821`, {
                headers: { 'x-auth': user.authToken },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Self-Employment Affidavit - ${account.firstName}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setFetching8821(false);
    }

    function handleClear() {
        sigCanvas?.current?.clear?.();
    }

    async function handleSubmit() {
        setSaving(true);
        try {
            if (radio !== 'yes') {
                setErrors({ radio: true });
                throw new Error('Please inform your consultant of the errors in the application');
            }
            if (!checkboxes.confirmed || !checkboxes.eight21 || !checkboxes.proof) {
                setErrors({ checkboxes: true });
                throw new Error('Please confirm the following information before continuing.');
            }
            if (account?.forms?.affidavit?.signature) {
                setErrors({ signature: true });
                throw new Error('Already signed');
            }
            if (!identification) {
                setErrors({ identification: true });
                throw new Error('Please provide identification');
            }
            if (sigCanvas.current.isEmpty()) {
                setErrors({ signature: true });
                throw new Error('Please sign before submitting');
            }
            setErrors({});

            const signature = sigCanvas.current.toDataURL();

            const formData = new FormData();
            formData.append('file', identification);
            formData.append('forms.affidavit', JSON.stringify({ unemployment: checkboxes.unemployment, signature, dateSigned: new Date().getTime() }));

            const response = await axios.patch(`${ApiResource.endpoint}/portal/applications/${account.model.AccountableId}`, formData, {
                headers: {
                    'x-auth': user.authToken,
                    'Content-Type': 'multipart/form-data',
                },
            });
            dispatch(AccountRedux.set(response.data));
            dispatch(setSnackbar({ message: 'You will receive an email shortly with your signed affidavit' }));
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setSaving(false);
    }

    async function handleDownload() {
        setSaving(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/portal/applications/${account.model.AccountableId}/affidavit`, {
                headers: { 'x-auth': user.authToken },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Self-Employment Affidavit - ${account.firstName}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setSaving(false);
    }

    function handleCheckboxClicked(idx, value) {
        const _checkboxes = cloneDeep(checkboxes);
        _checkboxes[idx] = value;
        setCheckboxes(_checkboxes);
    }

    console.log(errors);

    return (
        <Container maxWidth="lg">
            <Card title="Review your Selection(s)">
                <Box className={classes.section}>
                    <Typography variant="font2">
                        <span className="bold">My Name:</span> {account.firstName} {account.lastName}
                    </Typography>
                    <Typography variant="font2">
                        <span className="bold">What I do for work:</span> {account.fields.typeOfWork}
                    </Typography>
                </Box>
                {PARTS.map((v, i) => {
                    const split = v.part.split('_');
                    if (!account?.forms?.affidavit?.[`${split[0]}_qualify_${split[1]}_leave`])
                        return (
                            <Box className={classes.section} key={i}>
                                <Typography variant="font2" fontWeight={'600'}>
                                    PERIOD {v.period} {v.title} Leave
                                </Typography>

                                <ul>
                                    <li>
                                        <Typography variant="font2">I choose not to elect dates for this portion of the credit</Typography>
                                    </li>
                                </ul>
                            </Box>
                        );

                    return (
                        <Box className={classes.section} key={i}>
                            <Typography variant="font2" fontWeight={'600'}>
                                PERIOD {v.period} {v.title} Leave
                            </Typography>
                            <Typography variant="font2">{v.dates}</Typography>

                            <Typography variant="font2" fontWeight={'600'}>
                                Selected Reasons:
                            </Typography>
                            <ul>
                                {account?.forms?.affidavit?.[`${v.part}_reasons`]?.map?.((reason, i) => {
                                    return (
                                        <li key={i}>
                                            <Typography variant="font2">{reason}</Typography>
                                        </li>
                                    );
                                })}
                            </ul>
                            <Typography variant="font2" fontWeight={'600'}>
                                My statement to consultant:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="font2">{account?.forms?.affidavit?.[`${v.part}_specifically`]}</Typography>
                                </li>
                            </ul>
                            <Typography variant="font2" fontWeight={'600'}>
                                Dates which I elect {v.title} Leave:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="font2">
                                        {sortStringDates(account?.forms?.affidavit?.[`${v.part}_dates`] || [], 'MM/DD').join?.(', ')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    );
                })}

                {EXTRA_PARTS.map((v, i) => {
                    const split = v.part.split('_');
                    if (!account?.forms?.affidavit?.[`${split[0]}_qualify_${split[1]}_leave`])
                        return (
                            <Box className={classes.section} key={i}>
                                <Typography variant="font2" fontWeight={'600'}>
                                    {v.title} Sick Leave
                                </Typography>

                                <ul>
                                    <li>
                                        <Typography variant="font2">{v.noText}</Typography>
                                    </li>
                                </ul>
                            </Box>
                        );
                    return (
                        <Box className={classes.section} key={i}>
                            <Typography variant="font2" fontWeight={'600'}>
                                {v.title} Sick Leave
                            </Typography>
                            <Typography variant="font2">{v.dates}</Typography>
                            <Typography variant="font2" fontWeight={'600'}>
                                Selected Dates:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="font2">
                                        {sortStringDates(account?.forms?.affidavit?.[`${v.part}_dates`] || [], 'MM/DD').join?.(', ')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    );
                })}

                <Box className={classes.section} sx={{ borderBottom: 'none' }}>
                    <Typography variant="font1" fontWeight={'500'}>
                        Select One Below:
                    </Typography>

                    <RadioGroup value={radio} onChange={(e) => setRadio(e.target.value)}>
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            disabled={signed}
                            label={
                                <Typography variant="font1" fontWeight={'300'}>
                                    The information in this affidavit is true and correct to the best of my knowledge.
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            disabled={signed}
                            label={
                                <Typography variant="font1" fontWeight={'300'}>
                                    I have found errors in this affidavit and it is not wholly true and correct.
                                </Typography>
                            }
                        />
                    </RadioGroup>
                    {errors.radio && (
                        <Typography variant="font2" color="error.main">
                            Please inform your consultant of the errors in the application
                        </Typography>
                    )}

                    <Typography variant="font1" fontWeight={'500'} sx={{ mt: 2 }}>
                        By Providing my Signature below:
                    </Typography>

                    <FormGroup>
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={
                                <Checkbox
                                    defaultChecked={checkboxes.proof}
                                    disabled={signed}
                                    value={checkboxes.proof}
                                    onChange={(_, checked) => handleCheckboxClicked('proof', checked)}
                                />
                            }
                            label={
                                <Typography variant="font1" fontWeight={'300'}>
                                    If requested, I will be able to provide additional proof that fully substantiates the information provided in this
                                    affidavit.
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={
                                <Checkbox
                                    defaultChecked={checkboxes.eight21}
                                    disabled={signed}
                                    value={checkboxes.eight21}
                                    onChange={(_, checked) => handleCheckboxClicked('eight21', checked)}
                                />
                            }
                            label={
                                <Typography variant="font1" fontWeight={'300'}>
                                    By signing below, I agree to authorize Relief Consultants LLC + associates to view my tax information via an 8821 so The
                                    Relief Consultants can provide me updates regarding my 7202 credit and other tax information:{' '}
                                    <LoadingButton onClick={get8821} loading={fetching8821}>
                                        Listed here
                                    </LoadingButton>
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={
                                <Checkbox
                                    defaultChecked={checkboxes.unemployment}
                                    disabled={signed}
                                    value={checkboxes.unemployment}
                                    onChange={(_, checked) => handleCheckboxClicked('unemployment', checked)}
                                />
                            }
                            label={
                                <Typography variant="font1" fontWeight={'300'}>
                                    I have not received Unemployment benefits, whether it be UI or PUA, for the dates I have selected in this affidavit that I
                                    wish to claim for the "SETC" FFCRA Sick Leave Credit.
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={
                                <Checkbox
                                    disabled={signed}
                                    defaultChecked={checkboxes.confirmed}
                                    value={checkboxes.confirmed}
                                    onChange={(_, checked) => handleCheckboxClicked('confirmed', checked)}
                                />
                            }
                            label={
                                <Typography variant="font1" fontWeight={'300'}>
                                    I confirm here that I, {account.firstName} {account.lastName}, was unable to work including by means of telework, for the
                                    reasons mentioned above for any and all of the dates I have selected (listed above) in which I am claiming a Self Employed
                                    Sick Leave, Family Leave, or Child Care Leave Credit.
                                </Typography>
                            }
                        />
                    </FormGroup>
                    {errors.checkboxes && (
                        <Typography color="error.main" variant="font2">
                            Please confirm the following information before continuing.
                        </Typography>
                    )}

                    <Typography variant="font2" mt={2}>
                        Date: {dayjs().format('MMM DD, YYYY')}
                    </Typography>
                    <Typography variant="font2" mt={2}>
                        I hereby swear under penalty of perjury that I have reviewed all of the information provided in this affidavit and confirm it to be true
                        under penalty of perjury. I understand that falsifying statements, even in the slightest way can lead to penalties or jail time.
                    </Typography>

                    <Typography variant="font2" mt={2}>
                        Please attach a photo of your identification
                    </Typography>
                    <Input
                        disabled={account?.forms?.affidavit?.signature}
                        type="file"
                        error={errors.identification}
                        onChange={(e) => setId(e.target.files[0])}
                    />
                    <Box sx={{ mt: 2 }} className={classes.sigContainer}>
                        <Typography color={errors.signature ? 'error.main' : null} variant="font2">
                            {account?.forms?.affidavit?.signature ? 'Your Signature Below:' : 'Sign Below:'}
                        </Typography>
                        {account?.forms?.affidavit?.signature ? (
                            <img src={account.forms.affidavit.signature} className={classes.signature} style={{ height: 100, width: 330 }} />
                        ) : (
                            <ReactSignatureCanvas ref={sigCanvas} penColor="black" canvasProps={{ height: 100, width: 330, className: classes.signature }} />
                        )}
                    </Box>
                    <Box className={classes.actions} sx={{ mt: 2 }}>
                        {!signed && <Button onClick={onBack}>Edit Dates & Reasons</Button>}
                        {!readyToSign && (
                            <LoadingButton onClick={handleRefresh} loading={saving}>
                                Awaiting Consultant (Click to refresh)
                            </LoadingButton>
                        )}
                        {!signed && readyToSign && (
                            <LoadingButton disabled={saving} onClick={handleClear}>
                                Clear
                            </LoadingButton>
                        )}
                        {!signed && readyToSign && (
                            <LoadingButton loading={saving} onClick={handleSubmit}>
                                Submit
                            </LoadingButton>
                        )}
                        {signed && (
                            <LoadingButton loading={saving} onClick={handleDownload}>
                                Download Signed Affidavit
                            </LoadingButton>
                        )}
                    </Box>
                </Box>
            </Card>
        </Container>
    );
}
