import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import cloneDeep from 'lodash/cloneDeep';
import { SET_ACCOUNTS } from 'redux/reducers/accounts';

export default class AccountsRedux {
    static set = (payload) => (dispatch, getState) => {
        dispatch({
            type: SET_ACCOUNTS,
            payload,
        });
    };
    static reset = () => (dispatch, getState) => {
        dispatch({
            type: SET_ACCOUNTS,
            payload: [],
        });
    };
}
