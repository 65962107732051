import React from 'react';
//mods
//comps
import { Box, Chip, Grid, Typography } from '@mui/material';
//styles
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Card from 'components/Card/Card';
import Linkify from 'linkify-react';

export default function QualificationMatrixPlaceholder() {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));

    return (
        <Box className={classes.root}>
            <Card title="Qualification Matrix" sx={{ marginBottom: 2 }} className={classes.paper}>
                <Typography>We are in the process of inputting your qualification statement(s). Check back later or contact your consultant below.</Typography>
            </Card>

            <Card
                title="Consultant Information"
                subtitle="Feel free to reach out to your consultant for updates!"
                className={classes.paper}
                sx={{ marginTop: 3 }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight="600" mb={1}>
                            Consultant name
                        </Typography>
                        <Typography variant="h6" fontWeight="300">
                            {account?.User?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight="600" mb={1}>
                            Consultant contact phone
                        </Typography>
                        <Typography variant="h6" fontWeight="300">
                            {account?.User?.phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight="600" mb={1}>
                            Consultant Email
                        </Typography>
                        <Typography variant="h6" fontWeight="300">
                            {account?.User?.email}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
}
