import React from 'react';
// UI
import { Box, Typography } from '@mui/material';
// images
import useStyles from './styles';

/**
 * 
 * 
 * 
 Please upload documents related to your first PPP, if applicable.
    Important info:
    Amount of Loan
    Date Received
    If Forgiven
    Use of Funds
    60% used on payroll minimum
    If you did not signify the use of funds on any official documents, it may not be included.
 */

export default function PPPInformation1() {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography
                variant="font2"
                sx={{ fontWeight: '400', marginTop: 1, marginBottom: 3 }}
            >
                Please upload documents related to your 2nd PPP,{' '}
                <span className="bold italic">if applicable.</span>
            </Typography>
            <Typography variant="font2">Important Info</Typography>
            <ul>
                <li>
                    <Typography variant="font2">Amount of Loan</Typography>
                </li>
                <li>
                    <Typography variant="font2">Date Received</Typography>
                </li>
                <li>
                    <Typography variant="font2">If Forgiven</Typography>
                </li>
                <li>
                    <Typography variant="font2">Use of Funds</Typography>
                    <ul>
                        <li>
                            <Typography variant="font2">
                                60% used on payroll minimum
                            </Typography>
                        </li>
                    </ul>
                </li>
            </ul>
            <Typography variant="font2">
                If you did not signify the use of funds on any official
                documents, it may not be included.
            </Typography>
        </Box>
    );
}
