import * as React from 'react';
// components
import { Box } from '@mui/material';
import ChangeAccountButton from 'components/Buttons/ChangeAccountButton/ChangeAccountButton';
// Styles
import useStyles from './styles';

export default function BottomNav() {
    const classes = useStyles();

    return (
        <Box className={classes.bottomBar} sx={{ boxShadow: 3 }}>
            <ChangeAccountButton />
        </Box>
    );
}
