import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Card from 'components/Card/Card';
import breakdown7202 from 'utils/breakdown-7202';

export default function SEHome() {
    const classes = useStyles();
    const { account } = useSelector(({ account }) => ({ account }));

    const breakdown = breakdown7202(account.forms);

    return (
        <Box className={classes.root} component={Container} maxWidth={'lg'}>
            <Card title="Self Employment Sick Leave Credit">
                <Typography variant="font2">This section may be periodically updated until your consultant has finalized your credit.</Typography>
            </Card>
            <Card title="Eligible Amount">
                <Typography color="success.main" fontWeight={'500'} variant="h1">
                    ${breakdown.totals.combined.numberWithCommas(2)}
                </Typography>
            </Card>
            <Card
                titleChildren={
                    <>
                        <Typography variant="h6" fontWeight={'600'}>
                            2020 Breakdown
                        </Typography>
                        <Typography variant="font3">April 1st 2020 - December 31st 2020</Typography>
                    </>
                }
            >
                <Box className={classes.stats}>
                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Sick & Family Leave portion 2020
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown[2020].part1.numberWithCommas(2)}
                        </Typography>
                    </Box>

                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Child Leave portion 2020
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown[2020].part2.numberWithCommas(2)}
                        </Typography>
                    </Box>
                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Total
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown.totals[2020].numberWithCommas(2)}
                        </Typography>
                    </Box>
                </Box>
            </Card>
            <Card
                titleChildren={
                    <>
                        <Typography variant="h6" fontWeight={'600'}>
                            2021 Breakdown
                        </Typography>
                        <Typography variant="font3">January 1st 2021 - September 30th 2021</Typography>
                    </>
                }
            >
                <Box className={classes.stats}>
                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Sick & Family Leave 2021
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown[2021].part1.numberWithCommas(2)}
                        </Typography>
                    </Box>
                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Child Leave 2021
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown[2021].part2.numberWithCommas(2)}
                        </Typography>
                    </Box>
                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Sick & Family Leave 2021
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown[2021].part3.numberWithCommas(2)}
                        </Typography>
                    </Box>

                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Child Leave 2021
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown[2021].part4.numberWithCommas(2)}
                        </Typography>
                    </Box>
                    <Box className={classes.stat}>
                        <Typography variant="font1" fontWeight={'400'}>
                            Total
                        </Typography>
                        <Typography variant="h6" fontWeight={'500'} color="success.main">
                            ${breakdown.totals[2021].numberWithCommas(2)}
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
}
