import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import useStyles from './styles';

export function BreakdownListItem({ status, value, quarter }) {
    const classes = useStyles();

    function getColor() {
        if (status === 'Under Review') return 'warning.main';
        if (status === 'Ineligible') return 'error.main';
        if (status === 'Pending') return '';
        return 'success.main';
    }

    function getTitle() {
        if (status === 'Ineligible') return 'This quarter is Ineligible for the Employee Retention Credit';
        else if (status === 'Eligible Revenue') return `This quarter is eligible for the Employee Retention Credit under the Gross Receipts Test`;
        else if (status === 'Eligible Shutdown')
            return `This quarter is eligible for the Employee Retention Credit due to a Full or Partial Suspension of Operations`;
        return 'This quarter is currently under review and is not included in your total credit';
    }

    function getText() {
        if (['Ineligible', 'Pending'].includes(status)) {
            return '-';
        }

        return `$${value.numberWithCommas?.(2) ?? '0.00'}`;
    }

    return (
        <Box className={classes.breakdown}>
            <Tooltip arrow placement="top" title={getTitle(status)}>
                <Box>
                    <Typography variant="font1">{quarter.toUpperCase()} Credit</Typography>
                    <Typography variant="font3" fontWeight="600" color={getColor(status)}>
                        {status}
                    </Typography>
                </Box>
            </Tooltip>
            <Typography variant="font1" fontWeight="600">
                {getText(status)}
            </Typography>
        </Box>
    );
}
