import React, { useState } from 'react';
import {
    AppBar,
    Box,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullscreenDialog({ children, open, onClose }) {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <Close />
                    </IconButton>
                    <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div"
                    >
                        Close
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 2 }}>{children}</Box>
        </Dialog>
    );
}
