import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Container, Paper, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import TabPanel from 'components/Widgets/TabPanel/TabPanel';
import Onboard from './Components/Onboard';
import Agreements from './Components/Agreements';
import Documents from './Components/Documents';
import Calculating from './Components/Calculating';
import Signature941 from './Components/Signature941';
import ClaimSent from './Components/ClaimSent';
import Received from './Components/Received';
import { ERC_STATUS, ERC_STATUS_ORDER } from 'utils/constants';

export default function Status() {
    const classes = useStyles();
    const { account, notifications } = useSelector(({ account, notifications }) => ({ account, notifications }));
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [tab, setTab] = useState(0);
    const statusIndexes = useRef({
        agreements: ERC_STATUS_ORDER.LEAD_CONTRACT_SENT, // Agreements Signed
        documents: ERC_STATUS_ORDER.CLOSED_AWAITING_DOCS, // Documents Uploaded
        calculating: ERC_STATUS_ORDER.CLOSED_PROCESSING_DOCS, // Calculating Credit
        signatures: ERC_STATUS_ORDER.CLOSED_SIGNATURES, // 941x Signatures
        claimSent: ERC_STATUS_ORDER.CLOSED_PAYMENTS, // Claim Sent to IRS
        received: ERC_STATUS_ORDER.PAID_UNPAID, // Funds Received
    });
    const statusIndex = Object.values(ERC_STATUS)?.indexOf?.(account?.status);

    useEffect(() => {
        const disabled = [
            false,
            statusIndex < statusIndexes?.current?.agreements,
            statusIndex < statusIndexes?.current?.documents,
            statusIndex < statusIndexes?.current?.calculating,
            statusIndex < statusIndexes?.current?.signatures,
            statusIndex < statusIndexes?.current?.claimSent,
            statusIndex < statusIndexes?.current?.received,
        ];

        for (let i = 0; i < disabled.length; i++) {
            if (disabled[i]) {
                if (i === 0) {
                    setTab(0);
                    break;
                }
                setTab(i - 1);
                break;
            }

            if (i === disabled.length - 1) setTab(i);
        }
    }, [account]);

    const updates = {};
    notifications?.items?.forEach?.((item) => {
        if (item?.type === 'CreditUpdate' && !item?.readAt) {
            updates.credit = true;
        }
        if (item?.type === 'IRSUpdate' && !item?.readAt) {
            updates.status = true;
        }
    });

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.title}>
                    <Typography variant="h3">Refund Status</Typography>
                </Box>
                <Paper className={classes.paper}>
                    <Tabs
                        orientation={!isSmall ? 'vertical' : 'horizontal'}
                        scrollButtons={'auto'}
                        variant="scrollable"
                        value={tab}
                        allowScrollButtonsMobile
                        onChange={(e, v) => setTab(v)}
                        sx={{
                            borderRight: { xs: 0, md: 1 },
                            borderBottom: { xs: 1, md: 0 },
                            borderColor: '#ececec !important',
                            minWidth: '210px',
                            marginBottom: { xs: 3, md: 0 },
                        }}
                    >
                        <Tab sx={{ alignItems: 'flex-start' }} label="1. Onboarding Call" id={0} />
                        <Tab sx={{ alignItems: 'flex-start' }} label="2. Work Agreements" id={1} disabled={statusIndex < statusIndexes?.current?.agreements} />
                        <Tab sx={{ alignItems: 'flex-start' }} label="3. Document Uploads" id={2} disabled={statusIndex < statusIndexes?.current?.documents} />
                        <Tab
                            sx={{ alignItems: 'flex-start' }}
                            label="4. Calculating Credit"
                            id={3}
                            disabled={statusIndex < statusIndexes?.current?.calculating}
                        />
                        <Tab sx={{ alignItems: 'flex-start' }} label="5. 941x Signatures" id={4} disabled={statusIndex < statusIndexes?.current?.signatures} />
                        <Tab
                            sx={{ alignItems: 'flex-start' }}
                            label={
                                <Badge badgeContent={updates.status ? '!' : null} color={'primary'}>
                                    <span>6. Claim Updates</span>
                                </Badge>
                            }
                            id={5}
                            disabled={statusIndex < statusIndexes?.current?.claimSent}
                        />
                        <Tab sx={{ alignItems: 'flex-start' }} label="7. Funds Received" id={6} disabled={statusIndex < statusIndexes?.current?.received} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Onboard statusIndex={statusIndex} indexes={statusIndexes?.current} />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Agreements statusIndex={statusIndex} indexes={statusIndexes?.current} />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Documents statusIndex={statusIndex} indexes={statusIndexes?.current} />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <Calculating statusIndex={statusIndex} indexes={statusIndexes?.current} />
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <Signature941 statusIndex={statusIndex} indexes={statusIndexes?.current} />
                    </TabPanel>
                    <TabPanel value={tab} index={5}>
                        <ClaimSent statusIndex={statusIndex} indexes={statusIndexes?.current} />
                    </TabPanel>
                    <TabPanel value={tab} index={6}>
                        <Received statusIndex={statusIndex} indexes={statusIndexes?.current} />
                    </TabPanel>
                </Paper>
            </Container>
        </Box>
    );
}
