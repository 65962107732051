import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import ApiResource from 'integrations/api/api_resource';
import { SET_NOTIFICATIONS } from 'redux/reducers/notifications';

export default class NotificationsRedux {
    static get = () => async (dispatch, getState) => {
        const { user, account } = getState();
        const { model } = account;

        const response = await axios.get(`${ApiResource.endpoint}/portal/notifications/${model.AccountableId}`, {
            headers: { 'x-auth': user.authToken },
        });

        return dispatch({
            type: SET_NOTIFICATIONS,
            payload: response.data,
        });
    };

    static readAll = () => async (dispatch, getState) => {
        const { user, account, notifications } = cloneDeep(getState());
        const { model } = account;

        notifications.unread = 0;

        await axios.patch(`${ApiResource.endpoint}/portal/notifications/read-all/${model.AccountableId}`, null, {
            headers: { 'x-auth': user.authToken },
        });

        return dispatch({
            type: SET_NOTIFICATIONS,
            payload: notifications,
        });
    };

    static read = (notification) => async (dispatch, getState) => {
        const { user, account, notifications } = cloneDeep(getState());
        const { model } = account;

        if (notification.readAt) return;
        const { id } = notification;

        notifications.unread -= 1;
        notifications.items.forEach((n) => {
            if (n.id === id) n.readAt = new Date();
        });

        await axios.patch(`${ApiResource.endpoint}/portal/notifications/read/${id}/${model.AccountableId}`, null, {
            headers: { 'x-auth': user.authToken },
        });

        return dispatch({
            type: SET_NOTIFICATIONS,
            payload: notifications,
        });
    };

    static set = (payload) => (dispatch, getState) => {
        dispatch({
            type: SET_NOTIFICATIONS,
            payload,
        });
    };

    static reset = () => (dispatch, getState) => {
        dispatch({
            type: SET_NOTIFICATIONS,
            payload: { items: [], count: 0 },
        });
    };
}
