import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { SET_CURRENT_USER, LOGOUT, USER_ERROR } from '../reducers/user';
import cloneDeep from 'lodash/cloneDeep';
import AccountsRedux from './accounts';
import AccountRedux from './account';

export default class UserRedux {
    static getCurrentUser = () => async (dispatch, getState) => {
        const state = getState();
        if (!state.user) return;

        try {
            const response = await axios.get(`${ApiResource.endpoint}/v2/users/current?accounts=portal`, {
                headers: {
                    'x-auth': state.user.authToken,
                },
            });

            const { accounts, user } = response.data;

            const payload = { ...state.user, ...user };
            payload.lastSyncTime = new Date().getTime() / 1000;

            dispatch({
                payload,
                type: SET_CURRENT_USER,
            });

            dispatch(AccountsRedux.set(accounts));

            return payload;
        } catch (error) {
            if (error.response.status === 423) {
                dispatch(UserRedux.signOut());
            }
            throw error;
        }
    };

    static setUser = (payload) => (dispatch, getState) => {
        const user = cloneDeep(getState().user);

        payload = { ...user, ...payload };
        payload.isAdmin = payload.groups?.includes?.('Admin');

        dispatch({
            type: SET_CURRENT_USER,
            payload,
        });
    };

    static signOut = () => async (dispatch) => {
        try {
            dispatch(AccountsRedux.set([]));
            dispatch(AccountRedux.reset(null));

            dispatch({
                payload: null,
                type: LOGOUT,
            });

            return true;
        } catch (error) {
            console.log(error);
            dispatch({
                type: USER_ERROR,
            });

            return false;
        }
    };
}
