import React, { useState } from 'react';
// mods
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import ApiResource from 'integrations/api/api_resource';
import UserRedux from 'redux/actions/user';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useDispatch } from 'react-redux';
// comps
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// styles
import Logo from 'assets/images/logos/logo-long-dark.png';
import useStyles from './styles';

export default function ResetPassword() {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function handleSignup(data) {
        setSubmitting(true);
        try {
            const email = data.email.toLowerCase();
            const response = await axios.post(`${ApiResource.endpoint}/users`, {
                ...data,
                email,
            });

            dispatch(
                UserRedux.setUser({
                    authToken: response.data.token,
                    ...response.data.user,
                }),
            );
        } catch (error) {
            dispatch(
                setSnackbar({ severity: 'error', message: checkError(error) }),
            );
        }
        setSubmitting(false);
    }

    return (
        <Box
            className={classes.actionContent}
            component="form"
            onSubmit={handleSubmit(handleSignup)}
        >
            <img src={Logo} className={classes.logo} />
            <Typography variant="font1" sx={{ my: 2 }}>
                Reset Password Request
            </Typography>
            <Box className={classes.fieldSet} sx={{ marginBottom: 2 }}>
                <Typography variant="font1" mb={0.5}>
                    Email Address
                </Typography>
                <TextField
                    size="small"
                    disabled={true}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    placeholder="user@example.com"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email', {
                        required: 'Invalid email address',
                        validate: (value) => validator.isEmail(value),
                    })}
                />
            </Box>
            <Box className={classes.fieldSet} sx={{ marginBottom: 2 }}>
                <Typography variant="font1" mb={0.5}>
                    Password
                </Typography>
                <TextField
                    size="small"
                    fullWidth
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    placeholder="user@example.com"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email', {
                        required: 'Invalid email address',
                        validate: (value) => validator.isEmail(value),
                    })}
                />
            </Box>
            <Box className={classes.fieldSet} sx={{ marginBottom: 2 }}>
                <Typography variant="font1" mb={0.5}>
                    Re-enter New Password
                </Typography>
                <TextField
                    size="small"
                    fullWidth
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    placeholder="user@example.com"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email', {
                        required: 'Invalid email address',
                        validate: (value) => validator.isEmail(value),
                    })}
                />
            </Box>
            <input type="submit" style={{ display: 'none' }} />
            <LoadingButton
                fullWidth
                variant="contained"
                loading={submitting}
                onClick={handleSubmit(handleSignup)}
            >
                Sign In
            </LoadingButton>
        </Box>
    );
}
