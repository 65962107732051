import React from 'react';
import { South } from '@mui/icons-material';
import clsx from 'clsx';
import { Box, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import ApiResource from 'integrations/api/api_resource';

export default function ReportDownloadButton({ sx, ...rest }) {
    const classes = useStyles();
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));

    async function getReport() {
        if (!account?.metadata?.reportAvailable) return;
        window.open(`${ApiResource.endpoint}/v2/reports/${account.model.AccountableId}/erc_full_report?token=${user.authToken}`, '_blank');
    }

    return (
        <Tooltip
            placement="top"
            arrow
            title={account?.metadata?.reportAvailable ? '' : 'This report will be ready upon submission of your Employee Retention Credit'}
        >
            <Box
                onClick={() => getReport()}
                className={clsx(classes.report, {
                    [classes.disabledReport]: !account?.metadata?.reportAvailable,
                })}
                sx={{ boxShadow: 2, ...sx }}
                {...rest}
            >
                <Typography variant="h5">Employee Retention Credit Report</Typography>
                <Box className={classes.reportIcon}>
                    <South />
                </Box>
            </Box>
        </Tooltip>
    );
}
