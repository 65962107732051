import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: '0px 24px',
        minHeight: '300px',

        [theme.breakpoints.down('sm')]: {
            padding: '0px 12px',
        },
    },

    stat: {
        '& > *:first-child': {
            fontStyle: 'italic',
        },
    },

    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },

    evenColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },

    actions: {
        display: 'flex',
        gap: 12,
    },
}));

export default useStyles;
