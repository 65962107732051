import React, { useEffect } from 'react';
// Misc
import { useDispatch, useSelector } from 'react-redux';
// import { readNotifications } from 'redux/actions/notifications';
// MUI
import { Box } from '@mui/system';
import { Container, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Tab, Tabs, Hidden, Paper } from '@mui/material';
// Custom
import { NotificationIcon } from 'components/Navigation/NotificationsList/NotificationIcon';
import TabPanel from 'components/Widgets/TabPanel/TabPanel';
// images
// Styles
import useStyles from './styles';
import NotificationsRedux from 'redux/actions/notifications';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { useNavigate } from 'react-router-dom';

export default function Notifications(props) {
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { notifications } = useSelector(({ notifications }) => ({ notifications }));

    useEffect(() => {
        async function readAll() {
            try {
                await dispatch(NotificationsRedux.readAll());
            } catch (error) {
                dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
            }
        }

        readAll();
    }, []);

    function handleAction(notification) {
        dispatch(NotificationsRedux.read(notification));

        if (notification.type === 'CreditUpdate') {
            navigate('/');
        } else if (notification.type === 'IRSUpdate') {
            navigate('/status');
        } else if (notification.type === 'DocumentsNeeded') {
            navigate('/documents');
        }
    }

    return (
        <Container maxWidth={'xl'} className={classes.root}>
            <Box className={classes.title}>
                <Typography variant="h3">Notifications</Typography>
            </Box>
            <Paper sx={{ p: 4 }}>
                <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)} textColor="inherit" indicatorColor="transparent">
                    <Tab label="All Notifications" />
                </Tabs>
                <TabPanel value={activeTab} index={0} className={classes.tabContent}>
                    {notifications.items.length === 0 && (
                        <Box sx={{ padding: 2 }}>
                            <Typography>You have no notifications.</Typography>
                        </Box>
                    )}

                    {notifications.items.length > 0 ? (
                        <List classes={{ root: classes.list }}>
                            {notifications.items.map((notification, i) => {
                                return (
                                    <ListItem onClick={() => handleAction(notification)} button key={`noti-${i}`} classes={{ root: classes.listItem }}>
                                        <ListItemIcon>
                                            <Box className={classes.listItemIcon}>
                                                <NotificationIcon type={notification.type} />
                                            </Box>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={notification.meta.title}
                                            secondary={notification.meta.body}
                                            classes={{
                                                primary: classes.textPrimary,
                                                secondary: classes.textSecondary,
                                            }}
                                        />
                                        <Hidden smDown>
                                            <ListItemSecondaryAction classes={{ root: classes.listSecondaryAction }}>
                                                {notification.primary && <Typography>{notification.primary}</Typography>}
                                                {notification.secondary && <Typography>{notification.secondary}</Typography>}
                                            </ListItemSecondaryAction>
                                        </Hidden>
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : null}
                </TabPanel>
            </Paper>
        </Container>
    );
}
