import { combineReducers } from 'redux';

// Import reducers here
import accounts from './accounts';
import account from './account';
import notifications from './notifications';
import user from './user';
import snackbar from './snackbar';

const rootReducer = combineReducers({
    user,
    notifications,
    account,
    accounts,
    snackbar,
});

export default rootReducer;
