import * as React from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
// Styles
import useStyles from './styles';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import AccountRedux from 'redux/actions/account';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';

export default function ChangeAccountButton({ sx }) {
    const classes = useStyles();
    const { accounts, account } = useSelector(({ accounts, account }) => ({
        accounts,
        account,
    }));
    const dispatch = useDispatch();

    async function handleChangeAccount(id) {
        try {
            const value = accounts.find((a) => a.AccountableId === id);
            if (!value) throw new Error('Account not found?');

            await dispatch(AccountRedux.get(value));
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
    }

    function getAccountsName(f) {
        if (f.Form?.type === 'ERC') return f.Form?.companyName;
        else if (f.Form?.type === 'self-employment') return `SE ${f.Form?.firstName} ${f.Form?.lastName}`;
        else return 'Unknown form type';
    }

    function getAccountName(f) {
        if (f?.type === 'ERC') return f?.fields?.companyName;
        else if (f?.type === 'self-employment') return `SE ${f?.firstName} ${f?.lastName}`;
        else return 'Unknown form type';
    }

    return (
        <Box className={classes.root} sx={sx}>
            {account && accounts?.length > 1 && (
                <Select size={'small'} value={account.id} sx={{ maxWidth: '100%' }} onChange={(e) => handleChangeAccount(e.target.value)}>
                    {accounts.map((a) => {
                        return (
                            <MenuItem key={a.AccountableId} value={a.AccountableId}>
                                {getAccountsName(a)}
                            </MenuItem>
                        );
                    })}
                </Select>
            )}
            {account && accounts?.length === 1 && (
                <Box className={classes.name}>
                    <Typography fontWeight={'600'}>{getAccountName(account)}</Typography>
                </Box>
            )}
        </Box>
    );
}
