import React from 'react';
// UI
import { Box, Typography, Alert } from '@mui/material';
import SamplePayroll from 'components/Widgets/SamplePayroll/SamplePayroll';
// Styles
import useStyles from './styles';

/**
 * documents.payroll
 */

export default function PayrollDetails() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography
                variant="font2"
                sx={{ fontWeight: '600', marginTop: 1, marginBottom: 2 }}
            >
                Please upload in .CSV format if possible
            </Typography>
            <Typography
                variant="font2"
                sx={{ fontWeight: '400', marginTop: 1, marginBottom: 3 }}
            >
                To complete your ERC analysis we need detailed payroll
                information during the ERC program dates. Please ensure your
                upload contains all of the information outlined below. Providing
                inaccurate or incomplete documentation will lead to a
                significant delay and/or a reduction of your credit amount.
            </Typography>

            <Typography
                variant="font2"
                sx={{ fontWeight: '400', marginBottom: 2 }}
            >
                A detailed payroll report outlines all payments, deductions,
                contributions and taxes for each employee for each paycheck
                during your ERC eligibility period. We use this information to
                maximize the ERC funds we are able to claim. Include documents
                for <span className="bold underline">2020 (Q2, Q3, Q4)</span>{' '}
                and <span className="bold underline">2021 (Q1, Q2, Q3)</span>.
            </Typography>
            <Typography variant="font2" sx={{ fontWeight: '400' }}>
                Your report should contain the following:
            </Typography>
            <ul style={{ marginTop: 8 }}>
                <li>
                    <Typography>Employee Name</Typography>
                </li>
                <li>
                    <Typography>Check Date</Typography>
                </li>
                <li>
                    <Typography>Gross Wages</Typography>
                </li>
            </ul>

            <Typography variant="font2" sx={{ fontWeight: '400' }}>
                Sample Payroll
            </Typography>
            <SamplePayroll sx={{ marginTop: 1 }} />
            <Alert icon={false} sx={{ marginTop: 2 }} severity="warning">
                <Typography variant="font3" sx={{ fontWeight: '400' }}>
                    Also make sure your report{' '}
                    <span className="bold">includes:</span>
                </Typography>
                <ul style={{ marginTop: 8 }}>
                    <li>
                        <Typography variant="font3" sx={{ fontWeight: '400' }}>
                            Terminated Employee
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="font3" sx={{ fontWeight: '400' }}>
                            Majority Owners and relatives
                        </Typography>
                    </li>
                </ul>
                <Typography variant="font3" sx={{ fontWeight: '400' }}>
                    Do <span className="bold">not</span> include sensitive
                    employee information such as{' '}
                    <span className="bold">
                        Social Security Numbers, birthdates, addresses, or bank
                        account information. Don’t include W-2 documents or
                        paystubs.
                    </span>
                </Typography>
            </Alert>
        </Box>
    );
}
