import React, { useEffect, useRef, useState } from 'react';
// Modules
import AccountRedux from 'redux/actions/account';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
// MUI
import { Box, Container, Grid } from '@mui/material';
// Components
import FullscreenLoader from 'components/Widgets/FullscreenLoader';
import PPPInformation1 from 'components/Document/DocumentDetails/PPPInformation1';
import PPPInformation2 from 'components/Document/DocumentDetails/PPPInformation2';
import TaxDetails from 'components/Document/DocumentDetails/TaxDetails';
import HealthcareDetails from 'components/Document/DocumentDetails/HealthcareDetails';
import OtherDetails from 'components/Document/DocumentDetails/OtherDetails';
import PayrollDetails from 'components/Document/DocumentDetails/PayrollDetails';
import DocumentAccordion from 'components/Document/DocumentAccordion/DocumentAccordion';
import RevenueDetails from 'components/Document/DocumentDetails/RevenueDetails';
import PayrollInstructionsBox from 'components/Widgets/PayrollInstructionsBox/PayrollInstructionsBox';
// Styles
import useStyles from './styles';

export default function Upload() {
    const classes = useStyles();
    const { account, user } = useSelector(({ account, user }) => ({
        account,
        user,
    }));

    const [application, setApplication] = useState(null);
    const [files, setFiles] = useState(null);
    const dispatch = useDispatch();
    const [accordions, setAccordions] = useState([]);

    useEffect(() => {
        setApplication(cloneDeep(account));
        setFiles(cloneDeep(account.documents.uploads ?? {}));
    }, [account]);

    const handleAccordion = (value) => {
        const _accordions = cloneDeep(accordions);
        const index = _accordions.indexOf(value);
        if (index === -1) _accordions.push(value);
        else _accordions.splice(index, 1);

        setAccordions(_accordions);
    };

    function handleNewFile(fArr, newFiles, key) {
        if (!fArr) return;
        // name, key, type
        const _files = cloneDeep(files);
        _files[key] = fArr;
        setFiles(_files);

        handleSubmit(newFiles, key);
    }

    async function getPresignedUrl(filename, filetype) {
        const {
            data: { url },
        } = await axios.post(
            `${ApiResource.endpoint}/portal/applications/presigned/${application.model.AccountableId}`,
            {
                filename,
                filetype,
            },
            {
                headers: { 'x-auth': user.authToken },
            },
        );

        return url;
    }

    async function handleSubmit(_files, key) {
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/portal/applications/files/${application.model.AccountableId}`,
                {
                    uploads: { [key]: _files },
                },
                {
                    headers: { 'x-auth': user.authToken },
                },
            );

            await dispatch(AccountRedux.set(response.data));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    if (!application || !files) return <FullscreenLoader />;

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    {!account?.payroll?.details && (
                        <Grid item xs={12} md={12}>
                            <PayrollInstructionsBox />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <DocumentAccordion
                            title="1. Payroll Breakdown (.CSV)"
                            required
                            application={application}
                            getPresignedUrl={getPresignedUrl}
                            expanded={accordions.includes(1)}
                            onOpen={() => handleAccordion(1)}
                            details={<PayrollDetails />}
                            initialFiles={files.payroll || []}
                            onFilesChanged={(f, n) => handleNewFile(f, n, 'payroll')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DocumentAccordion
                            title="2. Revenue Documentation"
                            required
                            application={application}
                            getPresignedUrl={getPresignedUrl}
                            expanded={accordions.includes(2)}
                            onOpen={() => handleAccordion(2)}
                            details={<RevenueDetails />}
                            initialFiles={files.revenue || []}
                            onFilesChanged={(f, n) => handleNewFile(f, n, 'revenue')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DocumentAccordion
                            title="3. 941, 943, or 944 Tax Documents"
                            required
                            application={application}
                            getPresignedUrl={getPresignedUrl}
                            expanded={accordions.includes(3)}
                            onOpen={() => handleAccordion(3)}
                            details={<TaxDetails />}
                            initialFiles={files.taxes || []}
                            onFilesChanged={(f, n) => handleNewFile(f, n, 'taxes')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DocumentAccordion
                            title="4. PPP Draw 1 Information"
                            applicable
                            application={application}
                            getPresignedUrl={getPresignedUrl}
                            expanded={accordions.includes(4)}
                            onOpen={() => handleAccordion(4)}
                            details={<PPPInformation1 />}
                            initialFiles={files.ppp1 || []}
                            onFilesChanged={(f, n) => handleNewFile(f, n, 'ppp1')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DocumentAccordion
                            title="5. PPP Draw 2 Information"
                            applicable
                            application={application}
                            getPresignedUrl={getPresignedUrl}
                            expanded={accordions.includes(5)}
                            onOpen={() => handleAccordion(5)}
                            details={<PPPInformation2 />}
                            initialFiles={files.ppp2 || []}
                            onFilesChanged={(f, n) => handleNewFile(f, n, 'ppp2')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DocumentAccordion
                            title="6. Healthcare Plan Expenses (.CSV)"
                            applicable
                            application={application}
                            getPresignedUrl={getPresignedUrl}
                            expanded={accordions.includes(6)}
                            onOpen={() => handleAccordion(6)}
                            details={<HealthcareDetails />}
                            initialFiles={files.healthcare || []}
                            onFilesChanged={(f, n) => handleNewFile(f, n, 'healthcare')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DocumentAccordion
                            title="7. Other Documents"
                            application={application}
                            getPresignedUrl={getPresignedUrl}
                            expanded={accordions.includes(7)}
                            onOpen={() => handleAccordion(7)}
                            details={<OtherDetails />}
                            initialFiles={files.other || []}
                            onFilesChanged={(f, n) => handleNewFile(f, n, 'other')}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
