import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserRedux from 'redux/actions/user';
import { HOUR } from 'utils/constants';
import UnauthStack from 'containers/UnauthStack/UnauthStack';
import AuthStack from 'containers/AuthStack/AuthStack';
import AccountRedux from 'redux/actions/account';

/**
 * A HOC that routes a user to the appropriate navigation stack based on the authorizing function.
 * Must have access to the history.
 * @param {*} props
 */

const Authorizer = (props) => {
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const refreshing = useRef(false);

    useEffect(() => {
        refreshUser();
    }, []);

    async function refreshUser() {
        if (refreshing.current) return;
        refreshing.current = true;
        try {
            const date = new Date().getTime() / 1000;
            const shouldSync = true; //(user?.lastSyncTime || 0) + HOUR < date;

            if (user && shouldSync) {
                dispatch(UserRedux.getCurrentUser());
                dispatch(AccountRedux.current());
            }
        } catch (error) {
            console.log(error);
        }
        refreshing.current = false;
    }

    if (user) return <AuthStack />;

    return <UnauthStack />;
};

export default Authorizer;
