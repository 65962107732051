import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        display: 'flex !important',
        flexDirection: 'column',
        gap: 18,
    },
    stats: {
        display: 'flex',
        flexDirection: 'column',
        '& > div:not(:last-child)': {
            borderBottom: '1px solid #e4e4e4',
        },
    },
    stat: {
        padding: '12px 0px',
    },
}));

export default useStyles;
