import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    logo: {
        maxWidth: 300,

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}));

export default useStyles;
