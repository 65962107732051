import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';

const persistConfig = {
    key: 'root',
    storage: storage,
    // whitelist: ['user'],
};
const persistedReducer = persistReducer(persistConfig, reducers);

// export const store = createStore(persistedReducer, {}, enhancer);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
export const persistor = persistStore(store);

async function shouldClearLocalStorage() {
    if (typeof window !== 'undefined') {
        const clear = '3-clear';
        let value = localStorage.getItem(clear);
        if (!value) {
            persistor.purge();
            localStorage.clear();
            document.cookie.split(';').forEach((c) => {
                document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
            });

            localStorage.setItem(clear, 'done');
        }
    }
}
shouldClearLocalStorage();
