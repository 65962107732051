import React from 'react';
// Images
import { Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
// Styles
import useStyles from './styles';

const Dropdown = ({ children, title, placement = 'bottom-end', sx, clickaway = true, transformOrigin = 'center top', paperSx, ...props }) => {
    const classes = useStyles(props);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Box sx={{ ...sx }} className={classes.root} ref={anchorRef} onClick={handleToggle}>
                {title}
            </Box>
            <Popper sx={{ zIndex: 999 }} open={open} anchorEl={anchorRef.current} role={undefined} placement={placement} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        placement={placement}
                        {...TransitionProps}
                        style={{
                            transformOrigin,
                        }}
                    >
                        <Paper sx={paperSx} onClick={handleClose}>
                            {clickaway && <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>}
                            {!clickaway && children}
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default Dropdown;
